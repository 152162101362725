import { _urlSales, request} from "../api";
import {EMAILING_CONSTS} from "../constants/emailingConsts";
import swal from "sweetalert";
import {UTIL_CONSTS} from "../constants";
import {history} from "../../configs/history";


export const GetEmailing = () => {
    const requestData = {
        url: _urlSales,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: EMAILING_CONSTS.GET_EMAILING,
                    payload: res.data
                })
            })
    }
}

export const CreateEmailing = data => {
    const requestData = {
        url: _urlSales,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING,
        });
        return request(requestData)
            .then(res => {
                dispatch({
                    type:EMAILING_CONSTS.POST_EMAILING,
                    payload: res.data
                })
                swal({
                    title: "Հաջողվեց!",
                    text: "Էլ. հասցեները հաջողությամբ պահպանվեցին!",
                    icon: "success",
                    button: "Ok",
                });
                history.push('/admin/sales')
            }).finally(()=>{
                dispatch({
                    type: UTIL_CONSTS.END_LOADING,
                });
            })
    }
};


export const DeleteEmailingGroup = id => {
    const requestData = {
        url: `${_urlSales}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: EMAILING_CONSTS.DELETE_EMAILING,
                    payload: id
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Էլ. հասցեները հաջողությամբ ջնջվեցին!",
                    icon: "success",
                    button: "Ok",
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};


export const ResendEmailing = id => {
    const requestData = {
        url: `${_urlSales}/${id}`,
        token: true,
        method: "POST",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: EMAILING_CONSTS.RESEND_EMAILING,
                    payload: data
                });
            })
    }
}
