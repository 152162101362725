import {authSelectors} from "./authSelectors";
import {utilSelectors} from "./utilSelectors";
import {languagesSelectors} from "./languageSelectors";
import {mediaSelectors} from "./mediaSelectors";
import {categorySelectors} from "./categorySelectors";
import {sliderSelectors} from "./sliderSelectors";
import {contactSelectors} from "./contactSelectors";
import {feedbackTopicsSelectors} from "./feedbackTopicsSelectors";
import {servicesSelectors} from "./servicesSelectors";
import {projectSelectors} from "./projectSelectors";
import {vacanciesSelectors} from "./vacanciesSelectors";
import {menuSelectors} from "./menuSelectors";
import {staticTextSelectors} from "./staticTextsSelectors";
import {generalSelectors} from "./generalSelectors";
import {emailingSelectors} from "./emailingSelectors";
import {careersSelectors} from "./careersSelectors";

import {webSelectors} from "./front";

export default state => {
    return {
        ...authSelectors(state),
        ...utilSelectors(state),
        ...languagesSelectors(state),
        ...mediaSelectors(state),
        ...categorySelectors(state),
        ...sliderSelectors(state),
        ...contactSelectors(state),
        ...feedbackTopicsSelectors(state),
        ...servicesSelectors(state),
        ...projectSelectors(state),
        ...vacanciesSelectors(state),
        ...menuSelectors(state),
        ...staticTextSelectors(state),
        ...generalSelectors(state),
        ...emailingSelectors(state),
        ...careersSelectors(state),

        ...webSelectors(state),
    }
}
