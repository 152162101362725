import {SLIDER_CONSTS} from "../constants";

export const initialState = {
    slidersList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SLIDER_CONSTS.GET_SLIDER:
            return {
                ...state,
                slidersList: action.payload
            };
        case SLIDER_CONSTS.DELETE_SLIDE_BY_ID:
            return {
                ...state,
                slidersList: state.slidersList.filter(slider => slider.id !== action.payload)
            };
        default:
            return state;
    }
}