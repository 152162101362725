// Import packages
import React from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';

//Import styles
import "../assets/styles/front-styles/footer.scss";

//Import images
import {ReactComponent as ArrowTopIcon} from "../assets/images/front/icons/ic_arrow_top.svg";
import {ReactComponent as FbIcon} from "../assets/images/front/icons/ic_fb.svg";
import {ReactComponent as InIcon} from "../assets/images/front/icons/ic_in.svg";
import {ReactComponent as DribbbleIcon} from "../assets/images/front/icons/ic_dreeble.svg";
import {ReactComponent as Logo} from "../assets/images/icons/solidy_footer.svg";

//Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {getAppUrl} from "../utils/helperFunctions";

function Footer(props) {
    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    return (
        <footer ref={props.refProp}>
            <div className="footer">
                <div className="footer__content">
                    <Logo className="footer-logo"/>
                    <div className="footer__links">
                        <div className="footer__links__title">{props?.translation?.['footer-col-1-title']}</div>
                        <div onClick={() => props?.scrollToSection(1)}>{props.menuList?.[0]?.title}</div>
                        <div onClick={() => props?.scrollToSection(2)}>{props.menuList?.[1]?.title}</div>
                        <div onClick={() => props?.scrollToSection(3)}>{props.menuList?.[2]?.title}</div>
                        <div onClick={() => props?.scrollToSection(4)}>{props.menuList?.[3]?.title}</div>
                        <Link to={props?.menuList[4]?.url}>{props.menuList[4]?.title}</Link>
                    </div>
                    <div className="footer__links">
                        <div className="footer__links__title">{props?.translation?.['footer-col-2-title']}</div>
                        {props?.services?.slice(0, 4).map(item => {
                            return <a href={`/services#${item?.shortName?.trim()}`}
                                         key={item.id}>{item?.shortName}</a>
                        })}
                    </div>
                    <div className="footer__links">
                        <div className="footer__links__title">{props?.translation?.['footer-col-3-title']}</div>
                        {props?.projects?.slice(0, 4).map(item => {
                            return <a key={item.id}
                                      href={getAppUrl(item?.androidUrl, item?.iosUrl, item?.webUrl)}
                                      target={'_blank'}
                            >{item.title}</a>
                        })}
                    </div>
                    <div className="footer__links">
                        <div className="footer__links__title">{props?.translation?.['footer-col-4-title']}</div>
                        <a>{props?.contacts?.address}</a>
                        <a href={`tel:${props?.contacts?.phoneNumber}`}>{props?.contacts?.phoneNumber}</a>
                        <a href={`mailto:${props?.contacts?.email}`}>{props?.contacts?.email}</a>
                    </div>
                </div>
                <div className="social-wrapper">
                    <div className="social-wrapper__title">{props?.translation?.['footer-col-5-title']}</div>
                    <div className="social-items">
                        <a href={props?.contacts?.facebookUrl} target='_blank'>
                            <FbIcon className={'social-icon'}/>
                        </a>
                        <a href={props?.contacts?.linkedinUrl} target='_blank'>
                            <InIcon className={'social-icon'}/>
                        </a>
                        <a href={props?.contacts?.dribbbleUrl} target='_blank'>
                            <DribbbleIcon className={'social-icon'}/>
                        </a>
                    </div>
                </div>
                <button className="scroll-top" onClick={scrollTop}><ArrowTopIcon/></button>
            </div>
            <div className="copyright">{props?.contacts?.copyright}</div>
        </footer>
    );
}

export default connect(mapStateToProps)(Footer);