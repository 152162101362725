// Import packages
import React from "react";
import {connect} from 'react-redux';
import 'react-multi-carousel/lib/styles.css';
import {Link} from "react-router-dom";

//Import styles
import "../assets/styles/front-styles/projects.scss";

//Import components

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {generateFileMediaUrl} from "../utils/generateMemberMediaUrl";
import {ACCEPT_IMAGE_TYPES} from "../constants/acceptedTypes";

function Projects(props) {
    const {projects} = props

    return <section className="projects" ref={props.refProp}>
        <h2 className="section-title">{props?.translation && props.translation['projects-title']}</h2>
        <div className="section-description">{props?.translation && props.translation['projects-description']}</div>
        <div className="projects-item-wrapper">
            {
                projects?.map((item, index) => {
                    return <Link to={`/projects/${item.id}`}
                                 className={`project-item ${index > 5 ? 'project-item-new' : ''}`} key={item.id}
                                 style={{
                                     backgroundImage: `url(${generateFileMediaUrl(item?.logoPath?.path)})`
                                 }}>
                        {item?.logoPath?.path && !ACCEPT_IMAGE_TYPES?.includes(item?.extension) &&
                            <video className={"video"} key={item?.id} autoPlay playsInline muted loop poster={'true'}>
                                <source src={generateFileMediaUrl(item?.logoPath?.path)} type="video/mp4"/>
                            </video>
                        }
                        <div className="project-card-title">
                            <h3>{item?.title}</h3>
                        </div>
                        <div className="project-card-details" style={{backgroundColor: item?.color}}>
                            <h4 className={"project-card-details-title"}>{item?.title}</h4>
                            <p className={"project-card-details-text"}>{item?.description}</p>
                            <div className={"features-wrapper"}>
                                {!!item?.tags?.length && item?.tags.map((tag, index) => {
                                    return <div className="feature-item" key={tag + index}>
                                        {tag}
                                    </div>
                                })}
                            </div>
                        </div>
                    </Link>
                })
            }
        </div>
        <Link to={`/projects`} className="see-all">{props?.translation && props?.translation['see-all']}</Link>
    </section>

}

export default connect(mapStateToProps)(Projects);
