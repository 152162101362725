// Import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//import assets
import "./info.scss";

// Import components

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetVacanciesInfo, GetVacanciesWeb} from "../../redux/actions/front";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";

function Info(props) {

    useEffect(() => {
        props.GetVacanciesInfo()
    }, [])

    const {vacanciesInfo} = props;

    return <section className="vacancies_info">
        <div className="top_part">
            <div className="content">
                <div className="info_title">{vacanciesInfo?.texts?.title}</div>
                <div className="info_paragraph">{vacanciesInfo?.texts?.paragraph_1}</div>
            </div>
            <img className="info_img" src={generateImageMediaUrl(vacanciesInfo?.mediaArray?.[0]?.path)} alt=""/>
        </div>
        <div className="vacancies_paragraph">{vacanciesInfo?.texts?.paragraph_2}</div>
        <div className="vacancies_description_wrapper">
            <div className="sun-editor-editable vacancies_description"
                 dangerouslySetInnerHTML={{__html: vacanciesInfo?.texts?.description}}/>
            <img className="description_img" src={generateImageMediaUrl(vacanciesInfo?.mediaArray?.[1]?.path)} alt=""/>
        </div>
    </section>
}

const mapDispatchToProps = {
    GetVacanciesWeb,
    GetVacanciesInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(Info);