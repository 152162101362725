import {stringify} from "query-string";
import {request, _urlOauth, ClientEncoded} from "../api";
import {store} from "../store";
import {history} from "../../configs/history";
import {AUTH_CONSTS, UTIL_CONSTS} from "../constants";


export const LoginAdmin = loginCreds => {
    const requestData = {
        url:`${_urlOauth}/token`,
        method: "POST",
        data: stringify(loginCreds),
        customHeaders: {
            "Accept": "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
        }
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        history.push("/admin/media");
        request(requestData)
            .then(async ({data}) => {
                //scope: "access:admin"
                await dispatch({
                    type: AUTH_CONSTS.LOG_IN,
                    payload: data
                });
                history.push("/admin/media");
            })
    }
};

export const LogOut = () => {
    return dispatch => {
        dispatch({
            type: AUTH_CONSTS.LOG_OUT
        });
        history.push('/admin/login');
    }
};

export const TokenValidation = () => {
    const AccessToken = store.getState().auth.accessToken;
    const requestData = {
        url:`${_urlOauth}/authenticate`,
        method: "GET",
        customHeaders: {
            "Accept": "application/json",
            "x-access-token": AccessToken
        }
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });

        return request(requestData)
            .then(async (res) => {
                await dispatch({
                    type: AUTH_CONSTS.SET_ACCESS_PAGES,
                    payload: res?.data?.user?.accessPages
                });
                return res?.data?.accessToken;
            })
    }
};

export const RefreshToken = () => {
    const refToken = store.getState().auth.refreshToken;
    const requestData = {
        url: `${_urlOauth}/refresh-token`,
        method: "POST",
        data: stringify({
            grant_type: "refresh_token",
            refresh_token: refToken
        }),
        customHeaders: {
            "Content-Type": "application/x-www-form-urlencoded",
            "authorization": "Basic " + ClientEncoded,
        }
    };
    return request(requestData).then(async (response) => {

        response && await store.dispatch({
            type: AUTH_CONSTS.REFRESH_TOKEN,
            payload: response.data
        });
        return response && response.data && response.data.accessToken;
    }).catch(() => null)
};
