import {combineReducers} from "redux";

// import reducers
import auth from "./auth";
import utils from "./utils";
import languages from "./languages";
import media from "./media";
import categories from "./categories";
import slider from "./slider";
import contact from "./contact";
import feedbackTopics from "./feedbackTopics";
import services from "./services";
import projects from "./projects";
import vacancies from "./vacancies";
import menu from "./menu";
import staticText from "./staticText";
import general from "./general";
import emailing from "./emailing"
import careers from "./careers"

import web from "./front"

export default combineReducers({
    auth,
    utils,
    languages,
    media,
    categories,
    slider,
    contact,
    feedbackTopics,
    services,
    projects,
    vacancies,
    menu,
    staticText,
    general,
    emailing,
    careers,
    web,
});
