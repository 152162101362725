import {createSelector} from "reselect";
import {initialState} from "../reducers/auth";

const auth_S = state => state.auth;

const creatorFn = selectorKey => {
    return createSelector(auth_S, data => data[selectorKey])
};

export const authSelectors = state => {
    const sData = {};

    Object.keys(initialState).forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
