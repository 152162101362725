// Import packages
import React, {lazy, Suspense} from "react";
import {Redirect, Route, Router, Switch} from "react-router-dom";

// Import utils
import {history} from "../configs/history";

// Import pages
import FrontLayout from "../front-containers/FrontLayout";
import Vacancies from "../front-containers/vacancies/Vacancies";
import VacancyDetails from "../front-containers/vacancies/VacancyDetails";
import NotFound from "../front-containers/notFound/NotFound";
import Homepage from "../front-containers/homepage/Homepage";
import ServiceDetails from "../front-containers/serviceDetails/ServiceDetails";
import Contacts from "../front-containers/contacts/Contacts";
import Projects from "../front-containers/projects/Projects";
import ProjectDetails from "../front-containers/projects/ProjectDetails";
import About from "../front-containers/about/About";

// Import admin Route
const AdminRoute = lazy(() => import("./AdminRoutes"));

export default () => {
    const {location} = history;
    return <Router history={history}>
        {!(location?.pathname.startsWith('/admin')) ?
            <FrontLayout>
                <Switch>
                    <Route exact path="/" component={Homepage}/>
                    <Route exact path="/vacancies" component={Vacancies}/>
                    <Route exact path="/vacancy/:slug" component={VacancyDetails}/>
                    <Route exact path="/services" component={ServiceDetails}/>
                    <Route exact path="/projects" component={Projects}/>
                    <Route exact path="/projects/:id" component={ProjectDetails}/>
                    <Route exact path="/contacts" component={Contacts}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/404" component={NotFound}/>
                    <Route exact path="*" component={NotFound}/>
                </Switch>
            </FrontLayout>
            :
            <Suspense fallback={<div></div>}>
                <AdminRoute/>
            </Suspense>}
    </Router>
}
