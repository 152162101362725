import {createSelector} from "reselect";
import {initialState} from "../reducers/vacancies";

const vacancies_S = state => state.vacancies;

const creatorFn = selectorKey => {
    return createSelector(vacancies_S, data => data[selectorKey])
};

export const vacanciesSelectors = state => {
    const sData = {};

    Object.keys(initialState).forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};