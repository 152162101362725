import {AUTH_CONSTS, GENERAL_CONSTS} from "../constants";

export const initialState = {
    routesList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GENERAL_CONSTS.SET_ROUTES:
            return {
                ...state,
                routesList: action.payload,
            };
        case AUTH_CONSTS.LOG_OUT:
            return {
                ...state,
                routesList: []
            }
        default:
            return state;
    }
}