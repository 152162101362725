import {FEEDBACK_TOPICS_CONSTS} from "../constants/feedbackTopicsConsts";
import {request} from "../api/request";
import {_urlFeedbackTopics} from "../api/urls";
import swal from "sweetalert";
import {history} from "../../configs/history";

export const GetFeedbackTopics = () => {
    const requestData = {
        url: _urlFeedbackTopics,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FEEDBACK_TOPICS_CONSTS.GET_FEEDBACK_TOPICS_LIST,
                    payload: res.data
                })
            })
    }
};

export const CreateFeedbackTopic = data => {
    const requestData = {
        url: _urlFeedbackTopics,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FEEDBACK_TOPICS_CONSTS.CREATE_FEEDBACK_TOPIC,
                    payload: res.data
                })
                history.push('/admin/feedback-topics')
            })
    }
};

export const UpdateFeedbackTopicsPositions = items => {
    const requestData = {
        url: _urlFeedbackTopics,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: FEEDBACK_TOPICS_CONSTS.UPDATE_FEEDBACK_TOPICS_POSITIONS
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Թեման հաջողոըթյամբ թարմացվեց!",
                    icon: "success",
                    button: "Ok"
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const UpdateFeedbackTopic = (id, data) => {
    const requestData = {
        url: `${_urlFeedbackTopics}/${id}`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FEEDBACK_TOPICS_CONSTS.UPDATE_FEEDBACK_TOPIC_BY_ID,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Թեման հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                }).then(()=>{
                    history.push('/admin/feedback-topics')
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Ok"
                });
            })
    }
};

export const DeleteFeedbackTopic = id => {
    const requestData = {
        url: `${_urlFeedbackTopics}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: FEEDBACK_TOPICS_CONSTS.DELETE_FEEDBACK_TOPIC_BY_ID,
                    payload: id
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Թեման հաջողությամբ ջնջվեց!",
                    icon: "success",
                    button: "Ok",
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};