import {FEEDBACK_TOPICS_CONSTS} from "../constants";

export  const initialState = {
    feedbackTopicsList: []
};
export default (state = initialState, action) => {
    switch (action.type) {
        case FEEDBACK_TOPICS_CONSTS.GET_FEEDBACK_TOPICS_LIST:
            return {
                feedbackTopicsList: action.payload
            };
        case FEEDBACK_TOPICS_CONSTS.CREATE_FEEDBACK_TOPIC:
            return {
                feedbackTopicsList: [action.payload].concat(state.feedbackTopicsList)
            };
        case FEEDBACK_TOPICS_CONSTS.UPDATE_FEEDBACK_TOPIC_BY_ID:
            return {
                feedbackTopicsList: state.feedbackTopicsList.map(feedback =>
                    feedback.id === action.payload.id ? action.payload : feedback
                )
            };
        case FEEDBACK_TOPICS_CONSTS.DELETE_FEEDBACK_TOPIC_BY_ID:
            return {
                feedbackTopicsList: state.feedbackTopicsList.filter(feedback => feedback.id !== action.payload)
            };
        default:
            return state;
    }
}