// Import packages
import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

//Import styles
import "./services.scss";
import {ReactComponent as ArrowIcon} from "../../../assets/images/front/icons/ic_link_arrow.svg";

//Import utils
import {mapStateToProps} from "../../../redux/mapStateToProps";
import {LinkButton} from "../../../admin-components/buttons/buttons";

function Services(props) {
    return (
        <section className="services" ref={props.refProp}>
            <h2 className="section-title">{props?.translation?.['services-title']}</h2>
            <div className="section-description">{props?.translation?.['services-description']}</div>
            <div className="services-item-wrapper">
                {props?.services?.map(item => {
                    return <Link to={`/services#${item?.shortName?.trim()}`} className="services-item" key={item.id}>
                        <p className="services-item__description">{item.description}</p>
                        <h3 className="services-item__title">{item.title}</h3>
                        <button className="services-item__button"><ArrowIcon/></button>
                    </Link>
                })}
            </div>
            <div className="services__contact">
                <span className="contact-text">{props?.translation?.['services-contact-question']}</span>
                <LinkButton className="services__contact__button"
                            title={props?.translation?.['services-contact-button']}
                            link={'/contacts'}
                />
            </div>
        </section>
    );
}

export default connect(mapStateToProps)(Services);