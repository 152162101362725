import {VACANCIES_CONSTS} from "../constants";
import {request} from "../api";
import {_urlVacancies} from "../api";
import swal from "sweetalert";
import {history} from "../../configs/history";

export const GetVacancies = () => {
    const requestData = {
        url: _urlVacancies,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: VACANCIES_CONSTS.GET_VACANCIES_LIST,
                    payload: res.data
                })
            })
    }
};

export const CreateVacancy = data => {
    const requestData = {
        url: _urlVacancies,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: VACANCIES_CONSTS.CREATE_VACANCY,
                    payload: res.data
                })
                history.push('/admin/vacancies')
            })
    }
};

export const UpdateVacanciesPositions = items => {
    const requestData = {
        url: _urlVacancies,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: VACANCIES_CONSTS.UPDATE_VACANCIES_POSITIONS
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Աշխատատեղը հաջողոըթյամբ թարմացվեց!",
                    icon: "success",
                    button: "Ok"
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const UpdateVacancy = (id, data) => {
    const requestData = {
        url: `${_urlVacancies}/${id}`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: VACANCIES_CONSTS.UPDATE_VACANCY_BY_ID,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Աշխատատեղը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                }).then(()=>{
                    history.push('/admin/vacancies')
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Ok"
                });
            })
    }
};

export const DeleteVacancy = id => {
    const requestData = {
        url: `${_urlVacancies}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: VACANCIES_CONSTS.DELETE_VACANCY_BY_ID,
                    payload: id
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Աշխատատեղը հաջողությամբ ջնջվեց!",
                    icon: "success",
                    button: "Ok",
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};