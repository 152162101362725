import {GENERAL_CONSTS} from "../constants";

export const SetRoutes = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.SET_ROUTES,
            payload: data
        });

    }
};