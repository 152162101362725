import {COMPOSITE_CONSTS} from "../constants";
import {request} from "../api";
import {_urlContact} from "../api";
import swal from "sweetalert";

export const GetContact = () => {
    const requestData = {
        url: `${_urlContact}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(async res => {
                await dispatch({
                    type: COMPOSITE_CONSTS.GET_CONTACT,
                    payload: res.data
                })
            })
    }
};

export const UpdateContact = data => {
    const requestData = {
        url: `${_urlContact}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: COMPOSITE_CONSTS.UPDATE_CONTACT,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Կոնտակտը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                });
            })

    }
};
