// Import packages
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';

//Import styles
import "../assets/styles/front-styles/front-layout.scss";

//Import components
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {
    GetLanguagesWeb,
    GetContactsWeb,
    GetSliderWeb,
    GetCategoryWeb,
    GetServicesWeb,
    GetMenuWeb,
    GetProjectsWeb,
    GetTranslations, PathChange
} from "../redux/actions/front";
import {history} from "../configs/history";

class FrontLayout extends Component {
    constructor() {
        super();
        this.scrollTempIndex = null;
        this.section0 = React.createRef();
        this.section1 = React.createRef();
        this.section2 = React.createRef();
        this.section3 = React.createRef();
        this.section4 = React.createRef();
        this.section5 = React.createRef();
        this.section6 = React.createRef();

        this.scrollToSection = this.scrollToSection.bind(this);
    }

    componentDidMount() {
        const {selectedLanguage} = this.props;
        this.props.GetLanguagesWeb();
        this.props.GetSliderWeb(selectedLanguage.code);
        this.props.GetContactsWeb(selectedLanguage.code);
        this.props.GetCategoryWeb(selectedLanguage.code);
        this.props.GetServicesWeb(selectedLanguage.code);
        this.props.GetMenuWeb(selectedLanguage.code);
        this.props.GetProjectsWeb(selectedLanguage.code);
        this.props.GetTranslations();


        /*----------*/

        // window.onpopstate = (e) => {
        //     console.log('onpopstate', history.previous);
        //     // console.log(history.location, 'loc');
        //     if (e.state.key === '3a83av') {
        //         console.log('iiiiiifffff');
        //         this.props.PathChange(true)
        //     } else {
        //         console.log('elsee');
        //         this.props.PathChange(false)
        //     }
        // }
        // window.dispatchEvent = ()=> {
        //     console.log('dispatchEvent');
        //     console.log(history.location, 'loc');
        // }
    }

    scrollToSection(index) {
        if (this.props.location.pathname !== '/') {
            this.scrollTempIndex = index;
            history.push('/');
            return;
        }
        this.scrollTempIndex = null;
        let height = 0;
        if (index >= 0 && index <= 6) {
            for (let i = 0; i < index; i++) {
                height += this.props.heights?.[i];
            }
            window.scrollTo({
                top: index === 1 ? height : height,
                behavior: "smooth"
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname &&
            this.props.location.pathname === '/' && prevProps.location.pathname !== '/') {
            this.scrollToSection(this.scrollTempIndex);
        }
    }

    render() {
        const {children, pathChanged} = this.props;
        // console.log(pathChanged, 'pathChanged');
        return <div className={'front-layout-wrapper'}>
            <NavigationBar scrollToSection={this.scrollToSection}/>
            {children}
            <Footer refProp={this.section6} scrollToSection={this.scrollToSection}/>
        </div>
    }
}

const mapDispatchToProps = {
    GetLanguagesWeb,
    GetContactsWeb,
    GetSliderWeb,
    GetCategoryWeb,
    GetServicesWeb,
    GetMenuWeb,
    GetProjectsWeb,
    GetTranslations,
    PathChange
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FrontLayout));
