export const WEB_CONSTS = {
    //Languages
    SELECTED_LANGUAGE: "SELECTED_LANGUAGE",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    SET_ALL_LANGUAGE_WEB: "SET_ALL_LANGUAGE_WEB",

    //Static Texts
    SET_TRANSLATIONS: "SET_TRANSLATIONS",

    //Menu && Slider
    SET_MENU: "SET_MENU",
    SET_SLIDER: "SET_SLIDER",

    //Categories
    SET_ALL_CATEGORIES_WEB: "SET_ALL_CATEGORIES_WEB",

    //Services
    SET_ALL_SERVICES_WEB: "SET_ALL_SERVICES_WEB",

    //Projects
    SET_ALL_PROJECTS_WEB: "SET_ALL_PROJECTS_WEB",
    SET_PROJECT_BY_ID_WEB: "SET_PROJECT_BY_ID_WEB",
    CLEAR_PROJECT_BY_ID_WEB: "CLEAR_PROJECT_BY_ID_WEB",

    //FeedbackTopics
    SET_FEEDBACK_TOPICS_WEB: "SET_FEEDBACK_TOPICS_WEB",

    //Contact
    SET_CONTACT_WEB: "SET_CONTACT_WEB",

    //Custom Page
    SET_CUSTOM_PAGE: "SET_CUSTOM_PAGE",
    CLEAR_CUSTOM_PAGE: "CLEAR_CUSTOM_PAGE",

    //Vacancies
    SET_VACANCIES: "SET_VACANCIES",
    SET_VACANCIES_INFO: "SET_VACANCIES_INFO",
    GET_VACANCIES_COUNT: "GET_VACANCIES_COUNT",

    GET_HEIGHTS: "GET_HEIGHTS",
    PATH_CHANGE: "PATH_CHANGE",
};