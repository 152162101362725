import {ADMIN_RESOURCES_URL} from "../redux/api";
import defaultLogo from '../assets/images/defoult_img.svg';

export function generateImageMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? encodeURI(`${ADMIN_RESOURCES_URL}/images${path}`) : defaultLogo;
}

export function generateFileMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? encodeURI(`${ADMIN_RESOURCES_URL}/resources${path}`) : defaultLogo;
}
