import {createSelector} from "reselect";
import {initialState} from "../reducers/general";

const general_S = state => state.general;

const creatorFn = selectorKey => {
    return createSelector(general_S, data => data[selectorKey])
};

export const generalSelectors = state => {
    const sData = {};

    Object.keys(initialState).forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};