import {EMAILING_CONSTS} from "../constants";


export  const initialState = {
    emailingDate:[]
};

export default (state = initialState, action) => {
    switch (action.type){
        case EMAILING_CONSTS.GET_EMAILING:
            return {
                ...state,
                emailingDate: action.payload
            }
        case EMAILING_CONSTS.DELETE_EMAILING:
            return {
                emailingDate:state.emailingDate.filter(emails => emails.id !== action.payload)
            };
        case EMAILING_CONSTS.RESEND_EMAILING:
            return {
                emailingDate:[action.payload,...state.emailingDate]
            };
        default:
            return state
    }
}