// Import packages
import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

//Import styles
import "../assets/styles/front-styles/navigation-bar.scss";

//Import images
import {ReactComponent as Logo} from '../assets/images/front/solidy_logo.svg';
import {ReactComponent as LogoWhite} from '../assets/images/front/logo_white.svg';

//Import components

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetVacanciesWebCount} from "../redux/actions/front";
import {history} from "../configs/history";

function NavigationBar(props) {
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    useEffect(() => {
        props.GetVacanciesWebCount()
    }, []);
    const openMenu = () => {
        setMenuIsOpen(!menuIsOpen)
    };
    const scrollToSection = (index) => {
        props.scrollToSection && props.scrollToSection(index + 1)
        setMenuIsOpen(!menuIsOpen)
    };

    const navigate = (url) => {
        history.push(url)
        setMenuIsOpen(!menuIsOpen)
    };

    const responsive = {
        desktop: {
            breakpoint: {max: 4000, min: 1400},
            items: 8,
        },
        laptop: {
            breakpoint: {max: 1400, min: 600},
            items: 8,
        },
        mobile: {
            breakpoint: {max: 600, min: 450},
            items: 5,
        },
        xs: {
            breakpoint: {max: 450, min: 0},
            items: 4,
        },
    };

    return <nav className={`navigation-wrapper ${menuIsOpen ? '-open' : ''}`}>
        <div className="nav-header">
            {menuIsOpen ? <Link to={'/'}><LogoWhite title={''} className="logo"/></Link>
                : <Link to={'/'}><Logo title={''} className="logo"/></Link>}
            <div className={"nav-header-right"}>
                <Link to={"/vacancies"} className={"nav-header-item"}>Careers <span
                    className={"nav-header-item-badge"}><span>{props?.vacanciesCount}</span></span></Link>
                <button className={`menu-btn ${menuIsOpen ? '-open' : ''}`} onClick={openMenu}>
                    <span className="humb-icon">
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                    </span>
                    <span className="btn-text">Menu</span>
                </button>
            </div>
        </div>
        <div className="nav-dropdown">
            <div className="nav-menu">
                <div className="container">
                    <ul className="menu">
                        {
                            props?.menuList?.slice(0,5)?.map((item, index) => {
                                return <li className='menu-item'
                                           key={item.id}
                                           onClick={() => {
                                              item.url.length > 1 ? navigate(item?.url) : scrollToSection(index)
                                           }}
                                >{item.title}</li>
                            })
                        }
                        {
                            props?.menuList?.slice(5).map(item => {
                                return <li className='menu-item'
                                           key={item.id}
                                ><Link to={item?.url}>{item.title}</Link></li>
                            })
                        }
                    </ul>
                </div>
            </div>
            {/*<div className="nav-products">*/}
               {/* <div className="container">
                    <Carousel responsive={responsive} arrows={false}>
                        {
                            props?.projects?.map(item => {
                                return <div className="menu-slider-item" key={item.id}>
                                    <a className="item-content" href={item.url} target='_blank'>
                                        <img src={generateFileMediaUrl(item?.logoPathColored?.path)}
                                             className='item-img' alt=""/>
                                        <div className="item-text">{item.title}</div>
                                    </a>
                                </div>
                            })
                        }
                    </Carousel>
                </div>*/}
            {/*</div>*/}
        </div>
    </nav>
}

const mapDispatchToProps = {
    GetVacanciesWebCount
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
