import {WEB_CONSTS} from "../../constants/front";
import {StaticTextFields} from "../../../constants/StaticTexsts";


export const initialState = {
    languages: [],
    selectedLanguage: '',
    translationsData: StaticTextFields,
    translation: StaticTextFields.hy,

    menuList: [],
    sliderList: [],
    categories: [],
    services: [],
    projects: [],
    projectById: {},
    feedbackList: [],
    contacts: [],
    customPageData: {},
    vacancies: [],
    vacanciesInfo: {},
    vacanciesCount: "",

    heights: "",
    pathChanged: false
};

export default (state = initialState, action) => {
    switch (action.type) {

//Translations
        case WEB_CONSTS.SET_ALL_LANGUAGE_WEB:
            const mainLanguage = action.payload.find(lg => lg.isMain);
            const selectedLanguageIsExist = state.selectedLanguage && state.selectedLanguage.id
                && action.payload.find(lg => lg.id === state.selectedLanguage.id);

            const newSelectedLanguage = selectedLanguageIsExist ? state.selectedLanguage :
                (mainLanguage ? mainLanguage : action.payload[0]);

            const newState = {
                ...state,
                languages: action.payload,
                selectedLanguage: newSelectedLanguage,
            };
            if (state.translationsData && state.translationsData[newSelectedLanguage.code]) {
                newState.translation = state.translationsData[newSelectedLanguage.code]
            }
            return newState;

        case WEB_CONSTS.CHANGE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
                translation: (state.translationsData && state.translationsData[action.payload.code]) || {}
            };

        case WEB_CONSTS.SET_TRANSLATIONS:
            return {
                ...state,
                translationsData: action.payload,
                translation: (action.payload && state.selectedLanguage &&
                    state.selectedLanguage.code && action.payload[state.selectedLanguage.code]) || action.payload.hy
            };


// Menu reducer
        case WEB_CONSTS.SET_MENU:
            return {
                ...state,
                menuList: action.payload
            };
// Slider reducer
        case WEB_CONSTS.SET_SLIDER:
            return {
                ...state,
                sliderList: action.payload
            };

// Categories reducer
        case WEB_CONSTS.SET_ALL_CATEGORIES_WEB:
            return {
                ...state,
                categories: action.payload
            };

// Services reducer
        case WEB_CONSTS.SET_ALL_SERVICES_WEB:
            return {
                ...state,
                services: action.payload
            };

// Projects reducer
        case WEB_CONSTS.SET_ALL_PROJECTS_WEB:
            return {
                ...state,
                projects: action.payload
            };
        case WEB_CONSTS.SET_PROJECT_BY_ID_WEB:
            return {
                ...state,
                projectById: action.payload
            };
        case WEB_CONSTS.CLEAR_PROJECT_BY_ID_WEB:
            return {
                ...state,
                projectById: {}
            };
// Feedback Topics reducer
        case WEB_CONSTS.SET_FEEDBACK_TOPICS_WEB:
            return {
                ...state,
                feedbackList: action.payload
            };

//Contact reducer
        case WEB_CONSTS.SET_CONTACT_WEB:
            return {
                ...state,
                contacts: action.payload
            };

//Custom pages
        case WEB_CONSTS.SET_CUSTOM_PAGE:
            return {
                ...state,
                customPageData: action.payload
            };

        case WEB_CONSTS.CLEAR_CUSTOM_PAGE:
            return {
                ...state,
                customPageData: null,
            };
//Vacancies
        case WEB_CONSTS.SET_VACANCIES:
            return {
                ...state,
                vacancies: action.payload
            };
        case WEB_CONSTS.SET_VACANCIES_INFO:
            return {
                ...state,
                vacanciesInfo: action.payload
            };
        case WEB_CONSTS.GET_VACANCIES_COUNT:
            return {
                ...state,
                vacanciesCount: action.payload
            };


        case WEB_CONSTS.GET_HEIGHTS:
            return {
                ...state,
                heights: action.payload
            };

        case WEB_CONSTS.PATH_CHANGE:
            return {
                ...state,
                pathChanged: action.payload
            };

        default:
            return state;
    }
}
