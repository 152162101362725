// Import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//import assets
import "./about.scss";

// Import components

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";

function About(props) {
    useEffect(() => {

        window.scrollTo({
            top: 0,
        });
    }, [])

    const {} = props;

    return <section className="about_page">
        <div className="top_wrapper">
            <div className="about_page__title">Where technologies and innovations collide.</div>
            <div className="about_page__quantity_wrapper">
                <div className="quantity_item">
                    <div className="title">6+ years</div>
                    <div className="desc">In the market</div>
                </div>
                <div className="quantity_item">
                    <div className="title">5/5</div>
                    <div className="desc">Customer rating</div>
                </div>
                <div className="quantity_item">
                    <div className="title">70+</div>
                    <div className="desc">Customers worldwide</div>
                </div>
                <div className="quantity_item">
                    <div className="title">100+</div>
                    <div className="desc">Successfully completed projects</div>
                </div>
            </div>
        </div>

        <div className="paragraph">
            We have been proudly providing our partners with mobile, web and custom software solutions since 2017 and
            helping them undergo their digital transformation by creating products that bring value and ensure customer
            satisfaction.
        </div>

        <div className="about_page__description_wrapper">
            {/*<div className="sun-editor-editable vacancies_description"*/}
            {/*     dangerouslySetInnerHTML={{__html: vacanciesInfo?.texts?.description}}/>*/}
            <img className="description_img" src={'https://static.4steps.am/resources/careere.jpeg'} alt=""/>
        </div>

        <div className="about_page__members_wrapper">
            <div className="members_title">Our awesome team!</div>
            <div className="members_top_list">
                <div className="member_item">
                    <img className="member_item__img" src={'https://static.4steps.am/resources/member.png'} alt=""/>
                    <div className="member_item__content">
                        <div className="title">Bessie Cooper</div>
                        <div className="desc">Dog Trainer</div>
                    </div>
                </div>
                <div className="member_item">
                    <img className="member_item__img" src={'https://static.4steps.am/resources/member.png'} alt=""/>
                    <div className="member_item__content">
                        <div className="title">Bessie Cooper</div>
                        <div className="desc">Dog Trainer</div>
                    </div>
                </div>
                <div className="member_item">
                    <img className="member_item__img" src={'https://static.4steps.am/resources/member.png'} alt=""/>
                    <div className="member_item__content">
                        <div className="title">Bessie Cooper</div>
                        <div className="desc">Dog Trainer</div>
                    </div>
                </div>
            </div>
            <div className="members_bottom_list">
                <img className="member_img" src={'https://static.4steps.am/resources/member.png'} alt=""/>
                <img className="member_img" src={'https://static.4steps.am/resources/member.png'} alt=""/>
                <img className="member_img" src={'https://static.4steps.am/resources/member.png'} alt=""/>
                <img className="member_img" src={'https://static.4steps.am/resources/member.png'} alt=""/>
            </div>
        </div>
    </section>
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(About);