// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon} from 'antd';
import isEmail from "validator/lib/isEmail";

//import assets
import "./vacancy-details.scss";
import {ReactComponent as DateIcon} from '../../assets/images/front/icons/ic_date.svg';
import {ReactComponent as CalendarIcon} from '../../assets/images/front/icons/ic_calendar.svg';
import {ReactComponent as LevelIcon} from '../../assets/images/front/icons/ic_level.svg';
import {ReactComponent as MapIcon} from '../../assets/images/front/icons/ic_map.svg';
import {ReactComponent as TickIcon} from '../../assets/images/front/icons/ic_tick.svg';

// Import components
import NavigationBar from "../NavigationBar";
import {InputGroup} from "../../admin-components/uiElements/inputGroup";
import FileUploader from "../FileUploader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetVacanciesWeb} from "../../redux/actions/front";
import {getInputValues} from "../../utils/helperFunctions";
import {ApplyVacancy, PathChange} from "../../redux/actions/front";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {EMPLOYMENT_TERMS_TYPES, LEVELS_TYPES, SCHEDULES_TYPES} from "../../constants/constTypes";
import {history} from "../../configs/history";

class VacancyDetails extends Component {
    constructor() {
        super();
        this.state = {
            fieldsData: {
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                message: "",
                file: null
            },
            vacancyData: null,
            tick: false,
            loading: false,
            pageLoading: false
        };
        this.getInputValues = getInputValues.bind(this);
        this.getPhoneNumber = this.getPhoneNumber.bind(this);
        this.submitApplication = this.submitApplication.bind(this);
        this.showTick = this.showTick.bind(this);
    }

    componentDidMount() {
        this.setState({
            pageLoading: true
        })
        if (!this.props.location.state) {
            this.props.GetVacanciesWeb().then(() => {
                const vacancy = this.props.vacancies.find(item => item?.slug === this.props.match.params?.slug)
                if (vacancy?.id) {
                    this.setState({
                        pageLoading: false,
                        vacancyData: vacancy
                    })
                } else {
                    history.push("/404")
                }
            })
        } else {
            this.setState({
                pageLoading: false,
                vacancyData: this.props.location.state || null
            })
        }
        window.scrollTo({
            top: 0,
            // behavior: "smooth"
        });
    }

    componentWillUnmount() {
        this.props.PathChange(false)
    }


    getPhoneNumber({name, value}) {
        if (!/^[0-9]*$/.test(value)) {
            return;
        }
        this.setState({
            ...this.state,
            fieldsData: {
                ...this.state.fieldsData,
                [name]: value
            }
        })

    };

    validate() {
        const {fieldsData} = this.state;
        const errors = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if ((key !== 'message' && !fieldsData[key] )|| (key === 'email' && !isEmail(fieldsData?.email))
                || (key === 'phone' && !/^[+]?[0-9]{9,20}$/.test(fieldsData.phone) && fieldsData.phone?.length < 8)
            ) {
                errors[key] = true;
                result = false;
            }

        })
        this.setState({
            errors
        });
        return result;
    }

    submitApplication() {
        if (this.validate()) {
            this.setState({
                loading: true
            })
            let formData = new FormData();
            let reqData = this.state.fieldsData

            reqData = {
                ...reqData,
                phone: reqData.phone.startsWith('0') ? reqData.phone : `+374${reqData.phone}`
            }
            !reqData.message && ( delete  reqData.message)
            Object.keys(reqData).forEach(item => {
                formData.append(item, item === 'file' ? reqData[item]?.file : reqData[item]);
            })
            this.props.ApplyVacancy(this.state.vacancyData.id, formData).then(() => {
                this.setState({
                    fieldsData: {
                        firstName: "",
                        lastName: "",
                        phone: "",
                        email: "",
                        message: "",
                        file: null
                    },
                    tick: true,
                });
                this.showTick()
            }).finally(() => {
                this.setState({
                    loading: false,
                });
            })
        }
    }

    showTick() {
        setTimeout(
            () => this.setState({
                tick: false
            }),
            2000
        )
    }

    render() {
        const {fieldsData, errors, vacancyData, tick, loading} = this.state
        const {contacts, translation} = this.props

        return <>
            <NavigationBar/>
            {vacancyData?.id && <section className="vacancy-wrapper">
                {vacancyData?.logoPath.path && <div className={"vacancy-main-img-wrapper"}>
                    <img className={"main-img"} src={generateImageMediaUrl(vacancyData?.logoPath.path)} alt=""/>
                </div>}
                <div className={"vacancy-title-wrapper"}>
                    <h1 className={"vacancy-title"}>{vacancyData?.title}</h1>
                    <div className={"conditions-wrapper"}>
                        <div className={"condition-item"}>
                            <DateIcon/>
                            <span>{translation[EMPLOYMENT_TERMS_TYPES?.[vacancyData?.types?.[0]]]}</span>
                        </div>
                        {!!vacancyData?.schedules?.length && <div className={"condition-item"}>
                            <CalendarIcon/>
                            <span>{translation[SCHEDULES_TYPES?.[vacancyData?.schedules?.[0]]]}</span>
                        </div>}
                        {!!vacancyData?.levels?.length && <div className={"condition-item"}>
                            <LevelIcon/>
                            <span>{translation[LEVELS_TYPES?.[vacancyData?.levels?.[0]]]}</span>
                        </div>}
                        {contacts?.address && <div className={"condition-item"}>
                            <MapIcon/>
                            <span>{contacts?.address}</span>
                        </div>}
                    </div>
                </div>

                <div className={"vacancy-main-content"}>
                    <div className={"left-part"}>
                        {
                            !!vacancyData?.blocks?.length && vacancyData?.blocks?.map(item => {
                                return <div className={"left-part-section"} key={item.id}>
                                    <h2 className="section-title">{item?.title}</h2>
                                    <div className={"divider"}/>
                                    <div className="sun-editor-editable section-description"
                                         dangerouslySetInnerHTML={{__html: item?.description}}/>
                                </div>
                            })
                        }
                        {!!vacancyData?.skills?.length && <div className={"left-part-section"}>
                            <h2 className="section-title">
                                Skills
                            </h2>
                            <div className={"divider"}/>
                            <div className={"skills-wrapper"}>
                                {
                                    vacancyData?.skills?.map(item => {
                                        return <div className={"skill-item"} key={item?.id}>{item}</div>
                                    })
                                }
                            </div>
                        </div>}
                    </div>
                    <div className={"right-part"}>
                        <h3 className={"form-title"}>
                            Application
                        </h3>
                        <div className={"form-main-content"}>
                            <InputGroup
                                inputType="input"
                                type="text"
                                label={`First name`}
                                placeholder={"First name"}
                                required
                                error={errors?.firstName}
                                name={'firstName'}
                                value={fieldsData?.firstName}
                                onChange={this.getInputValues}
                            />
                            <InputGroup
                                inputType="input"
                                type="text"
                                label={`Last name`}
                                placeholder={"Last name"}
                                required
                                error={errors?.lastName}
                                name={'lastName'}
                                value={fieldsData?.lastName}
                                onChange={this.getInputValues}
                            />
                            <InputGroup
                                inputType={'input'}
                                type={'text'}
                                label={'Phone Number'}
                                required
                                error={errors?.phone}
                                name={'phone'}
                                value={fieldsData?.phone}
                                // maxLength={8}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 && !e.shiftKey && fieldsData?.phone.length >= 8) {
                                        // this.sendConfirmationCodeToPhone()
                                    }
                                }}
                                onChange={this.getPhoneNumber}
                                className={'phone'}
                            >
                                <span className="phone-code">+374</span>
                            </InputGroup>
                            <InputGroup
                                inputType="input"
                                type="text"
                                label={`Email`}
                                placeholder={"Email"}
                                required
                                error={errors?.email}
                                name={'email'}
                                value={fieldsData?.email}
                                onChange={this.getInputValues}
                            />
                            <InputGroup
                                inputType="textarea"
                                type="text"
                                label={`Tell us more about you`}
                                placeholder={"Tell us more about you"}
                                name={'message'}
                                value={fieldsData?.message}
                                onChange={this.getInputValues}
                            />
                            <div className={"input-group"}>
                                <label>Resume <span className={"required-field-badge"}>*</span></label>
                                <FileUploader getValue={this.getInputValues} error={errors?.file}
                                              file={fieldsData?.file}/>
                            </div>
                        </div>
                        {/*<LinkButton title={"Submit application"} className={"submit-btn"} cb={this.submitApplication}/>*/}
                        <button className='link-button submit-btn'
                                onClick={() => {
                                    if (!loading && this.submitApplication) {
                                        this.submitApplication();
                                    }
                                }}>
                            Submit application
                            {
                                loading ? <Icon type="loading" style={{fontSize: 14, color: "#fff"}}/>
                                    : <TickIcon title={''}
                                                className={`tick-icon ${tick ? 'show-tick' : 'hide-tick'}`}/>
                            }
                        </button>
                    </div>
                </div>
            </section>}
        </>
    }
}

const mapDispatchToProps = {
    ApplyVacancy,
    GetVacanciesWeb,
    PathChange
};

export default connect(mapStateToProps, mapDispatchToProps)(VacancyDetails);