// Import packages
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {
    YMaps,
    Map,
    Placemark,
} from 'react-yandex-maps';
import isEmail from "validator/lib/isEmail";

//Import styles
import "./contacts.scss";

//Import contacts
import {InputGroup} from "../../admin-components/uiElements/inputGroup";
import {LinkButton} from "../../admin-components/buttons/buttons";

//Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {phoneNumberWithSpaces} from "../../utils/helperFunctions";
import {SendContactsEmail} from "../../redux/actions/front";

function Contacts({contacts, translation, SendContactsEmail, feedbackList}) {
    //State
    const [contact, setContact] = useState({
        email: '',
        feedbackTopic: undefined,
        name: '',
        message: '',
    });
    const [errors, setErrors] = useState({
        email: false,
        feedbackTopic: false,
        name: false,
        message: false,
    });
    const [requestLoading, setRequestLoading] = useState(false);
    const [message, setMessage] = useState('');


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    //Functions
    function getInputValues({name, value,}) {
        setContact({
            ...contact,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    function validate() {
        const errors = {};
        let result = true;
        Object.keys(contact).forEach(key => {
            if (!contact[key] || (key === 'email' && !isEmail(contact?.email))
            ) {
                errors[key] = true;
                result = false;
            }
        })
        setErrors(errors);
        return result;
    }

    async function sendMessage() {
        await setRequestLoading(true);

        if (validate()) {
            SendContactsEmail(contact).then(() => {
                setContact({
                    email: '',
                    feedbackTopic: undefined,
                    name: '',
                    message: '',
                });
                setErrors({
                    email: false,
                    feedbackTopic: false,
                    name: false,
                    message: false,
                });
                setMessage(translation && translation['contacts-success-message']);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }).catch(() => {

            }).finally(() => {
                setRequestLoading(false)
            })
        } else {
            setRequestLoading(false)
        }
    }

    return (
        <section className="contacts_page">
            <div className="contacts_page__content">
                <div className="info">
                    <div>
                        <h1>{translation?.['contacts-page-title']}</h1>
                    </div>
                    <div>
                        <a href={`mailto:${contacts?.email}`}>{contacts?.email}</a>
                        <a href={`tel:${contacts?.phoneNumber}`}>{phoneNumberWithSpaces(contacts?.phoneNumber)}</a>
                        <a href={`tel:${contacts?.phoneNumber}`}>{phoneNumberWithSpaces('+37411444455')}</a>
                    </div>
                </div>
                <div className="form">
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'email'}
                        value={contact.email}
                        error={errors.email}
                        label={translation && translation['contacts-email-label']}
                        placeholder={translation && translation['contacts-email-placeholder']}
                        maxLength={50}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'selectCustom'}
                        name={'feedbackTopic'}
                        value={contact.feedbackTopic}
                        error={errors.feedbackTopic}
                        options={feedbackList}
                        showArrow={false}
                        optionFilterProp="children"
                        label={translation && translation['contacts-feedback-topic-label']}
                        placeholder={translation && translation['contacts-feedback-topic-placeholder']}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'name'}
                        value={contact.name}
                        error={errors.name}
                        label={translation && translation['contacts-name-label']}
                        placeholder={translation && translation['contacts-name-placeholder']}
                        maxLength={50}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'textarea'}
                        type={"text"}
                        name={'message'}
                        value={contact.message}
                        error={errors.message}
                        label={translation && translation['contacts-description-label']}
                        placeholder={translation && translation['contacts-description-placeholder']}
                        maxLength={2000}
                        onChange={getInputValues}
                    />
                    <div className="success-message">{message}</div>
                    <div className="btn-wrapper">
                        <LinkButton
                            loading={requestLoading}
                            className="send-btn"
                            cb={sendMessage}
                            title={translation && translation['contacts-send-message-button']}/>
                    </div>
                </div>
            </div>
            <div className="contacts_page__address">
                {contacts.address}
            </div>
            <div className="contacts_page__map">
                <YMaps query={{
                    apikey: 'c5f70ecb-be1a-4260-9292-9ecafceda596',
                    // lang: `${selectedLanguage?.code}_${selectedLanguage?.code?.toUpperCase()}`
                }}>
                    <Map
                        state={{
                            center: [40.190960, 44.503878],
                            zoom: 16 || 'MAP_DEFAULT_ZOOM',
                            hue: "ff0000",
                        }}
                        modules={["Placemark", "geocode", "geoObject.addon.balloon", "templateLayoutFactory", "layout.ImageWithContent"]}
                        width={'100%'} height={'100%'}
                    >
                        <Placemark
                            geometry={[40.190960, 44.503878]}
                            options={{
                                draggable: false,
                                iconLayout: 'default#image',
                                iconImageHref: 'https://static.4steps.am/resources/map.svg',
                                iconImageSize: [60, 130],
                                iconImageOffset: [-30, -100]
                            }}

                        />
                    </Map>
                </YMaps>
            </div>
        </section>
    );
}

const mapDispatchToProps = {
    SendContactsEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);