import {SERVICES_CONSTS} from "../constants";
import {request} from "../api/request";
import {_urlServices} from "../api/urls";
import swal from "sweetalert";
import {history} from "../../configs/history";

export const GetServices = () => {
    const requestData = {
        url: _urlServices,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SERVICES_CONSTS.GET_SERVICES_LIST,
                    payload: res.data
                })
            })
    }
};

export const CreateService = data => {
    const requestData = {
        url: _urlServices,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SERVICES_CONSTS.CREATE_SERVICE,
                    payload: res.data
                })
                history.push('/admin/services')
            })
    }
};

export const UpdateServicesPositions = items => {
    const requestData = {
        url: _urlServices,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SERVICES_CONSTS.UPDATE_SERVICES_POSITIONS
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Ծառայությունը հաջողոըթյամբ թարմացվեց!",
                    icon: "success",
                    button: "Ok"
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const UpdateService = (id, data) => {
    const requestData = {
        url: `${_urlServices}/${id}`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SERVICES_CONSTS.UPDATE_SERVICE_BY_ID,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Ծառայությունը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                }).then(() => {
                    history.push('/admin/services')
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Ok"
                });
            })
    }
};

export const DeleteService = id => {
    const requestData = {
        url: `${_urlServices}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SERVICES_CONSTS.DELETE_SERVICE_BY_ID,
                    payload: id
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Ծառայությունը հաջողությամբ ջնջվեց!",
                    icon: "success",
                    button: "Ok",
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

/*----------- Blocks -------------*/

export const CreateServiceBlock = (data, serviceId) => {
    const requestData = {
        url: `${_urlServices}/block`,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                history.push(`/admin/edit-services/${serviceId}`)
            })
    }
};

export const UpdateServiceBlock = (id, data) => {
    const requestData = {
        url: `${_urlServices}/block/${id}`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SERVICES_CONSTS.UPDATE_SERVICE_BY_ID,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Ծառայությունը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                }).then(() => {
                    history.push('/admin/services')
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Ok"
                });
            })
    }
};

export const UpdateServicesBlockPositions = items => {
    const requestData = {
        url: `${_urlServices}/block`,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                swal({
                    title: "Հաջողվեց",
                    text: "Ծառայությունը հաջողոըթյամբ թարմացվեց!",
                    icon: "success",
                    button: "Ok"
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const DeleteServiceBlock = (id, serviceId) => {
    const requestData = {
        url: `${_urlServices}/block/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SERVICES_CONSTS.DELETE_SERVICE_BLOCK_BY_ID,
                    payload: {id, serviceId}
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Բաժինը հաջողությամբ ջնջվեց!",
                    icon: "success",
                    button: "Ok",
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};
