import {MENU_CONSTS} from "../constants";
import {request} from "../api/request";
import {_urlMenu} from "../api";
import swal from "sweetalert";
import {history } from "../../configs/history";

export const GetMenuList = () => {
    const requestData = {
        url: _urlMenu,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: MENU_CONSTS.GET_MENU_LIST,
                    payload: res.data,
                })
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const CreateMenuItem = (data) => {
    const requestData = {
        url: _urlMenu,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: MENU_CONSTS.CREATE_MENU,
                    payload: res.data
                });
                history.push(`/admin/menu`)
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const UpdateMenuItemsPositions = items => {
    const requestData = {
        url: _urlMenu,
        token: true,
        method: "PUT",
        data: items
    };
    return request(requestData)
        .then(() => {
            swal({
                title: "Հաջողվեց",
                text: "Մենյուն հաջողոըթյամբ թարմացվեց!",
                icon: "success",
                button: "Ok"
            });
        })
        .catch(() => {
            swal({
                title: "Oops!",
                text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                icon: "warning",
                button: "Լավ"
            });
        })
};

export const UpdateMenuItem = (id, data) => {
    const requestData = {
        url: `${_urlMenu}/${id}`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: MENU_CONSTS.UPDATE_MENU_BY_ID,
                    payload: res.data
                })
                swal({
                    title: "Հաջողվեց",
                    text: "Մենյուն հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                });
                history.push('/admin/menu')
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const DeleteMenuItem = (id) => {
    const requestData = {
        url: `${_urlMenu}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: MENU_CONSTS.DELETE_MENU_BY_ID,
                    payload: id
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Մենյուն հաջողությամբ ջնջվեց!",
                    icon: "success",
                    button: "Ok",
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};
