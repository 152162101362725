export function getBrowserType() {
    let N = navigator.appName, ua = navigator.userAgent, tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
    if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
    M = M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];
    M = M[0];


    /*    let browserPrefix='';
        if(M == "Chrome") { browserPrefix = "webkit"; }
        if(M == "Firefox") { browserPrefix = "moz"; }
        if(M == "Safari") { browserPrefix = "webkit"; }
        if(M == "MSIE") { browserPrefix = "ms"; }*/


    return M;
}
