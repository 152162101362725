// Import packages
import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

//Import styles
import "./ranges.scss";

//Import components
import {mapStateToProps} from "../../../redux/mapStateToProps";

function Ranges(props) {
    const ranges = [
        {
            percent: props?.translation?.['ranges-percent-1'],
            text: props?.translation?.['ranges-text-1'],
            title: props?.translation?.['ranges-title-1'],
            id: '01'
        }, {
            percent: props?.translation?.['ranges-percent-2'],
            text: props?.translation?.['ranges-text-2'],
            title: props?.translation?.['ranges-title-2'],
            id: '02'
        }, {
            percent: props?.translation?.['ranges-percent-3'],
            text: props?.translation?.['ranges-text-3'],
            title: props?.translation?.['ranges-title-3'],
            id: '03'
        }, {
            percent: props?.translation?.['ranges-percent-4'],
            text: props?.translation?.['ranges-text-4'],
            title: props?.translation?.['ranges-title-4'],
            id: '04'
        }, {
            percent: props?.translation?.['ranges-percent-5'],
            text: props?.translation?.['ranges-text-5'],
            title: props?.translation?.['ranges-title-5'],
            id: '05'
        },
    ]
    return (
        <section className="ranges">
            <h2 className="section-title">{props?.translation?.['ranges-title']}</h2>
            <div className="section-description">{props?.translation?.['ranges-description']}</div>
            <div className="ranges-item-wrapper">
                {ranges?.map(item => {
                    return <div className="ranges-item" key={item.id}>
                        <div className="ranges-item__percent">{item?.percent}%</div>
                        <p className="ranges-item__text">{item.text}</p>
                        <p className="ranges-item__title">{item.title}</p>
                    </div>
                })}
            </div>
            <div className="ranges__contact">
                <span className="blue-text">{props?.translation?.['ranges-contact-text']}</span>
                <Link to={'/vacancies'} className="ranges__contact__button">{props?.translation?.['ranges-contact-button']}</Link>
            </div>
        </section>
    );
}

export default connect(mapStateToProps)(Ranges);