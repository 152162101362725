// Import packages
import React from 'react';
import {connect} from "react-redux";

//Import styles
import "./main.scss";

//Import images
import {generateFileMediaUrl} from "../../../utils/generateMemberMediaUrl";

//Import components
import {mapStateToProps} from "../../../redux/mapStateToProps";
import {LinkButton} from "../../../admin-components/buttons/buttons";

function Main(props) {
    return (
        <section className="main" ref={props.refProp}>
            <div className="main__slider-wrapper">
                <div className="content">
                    <div className="text-content">
                        <h1 className="main-title">
                            {props?.translation?.['main-title']}
                            <span className="blue-text">{props?.translation?.['main-typing-text-first']}</span>
                        </h1>

                        <div className="main-description">
                            {props?.translation?.['main-description']}
                        </div>
                    </div>
                    <div className="link">
                        <span className="blue-text">{props?.translation?.['main-question-title']}</span>
                        <LinkButton className="link-btn"
                                    title={props?.translation?.['main-contact-button']}
                                    link={'/contacts'}/>
                    </div>
                </div>

                {props.sliderList[0]?.mediaMain &&
                    <img src={generateFileMediaUrl(props.sliderList[0]?.mediaMain?.path)}
                         className={'main-img'} alt=""/>}
            </div>

            <div className="main__paragraph">
                <div className="main__paragraph__content">
                    <div className="main__paragraph__title">
                        {props.translation['main-paragraph-title']}
                    </div>
                    <div className="main__paragraph__description">
                        {props.translation['main-paragraph-description-1']}
                    </div>
                    <div className="main__paragraph__description">
                        {props.translation['main-paragraph-description-2']}
                    </div>
                </div>
            </div>

        </section>
    );
}

export default connect(mapStateToProps)(Main);