import {getMobileOS} from "./getMobileOS";
import {getBrowserType} from "./getBrowserType";
import swal from "sweetalert";

export function changeStateField(field, value) {
    this.setState({
        [field]: value,
    })
}

export function toggleStateField(field) {
    // console.log(field,"FILE")
    this.setState({
        [field]: !this.state[field],
    })
}

export function deleteItem(deletingFunction, itemName, id) {
    swal({
        title: "Զգուշացում!",
        text: `Ցանկանում եք ջնջել ${itemName}?`,
        icon: "warning",
        buttons: ["Ոչ", "Այո"]
    }).then(confirm => {
        if (confirm) {
            deletingFunction(id);
        }
    });
}

export function initTranslations(fieldsList, activeLanguages) {
    const translationsData = {};
    activeLanguages && activeLanguages.forEach(lg => {
        translationsData[lg.id] = {};
        fieldsList.forEach(field => {
            translationsData[lg.id][field] = '';
        })

    });
    return translationsData;
}

export function getTranslationData(translations) {
    let TR_data = [];
    Object.keys(translations).forEach(key => {
        const reqTrItem = {
            language: key,
        };
        const trItem = translations[key];
        Object.keys(trItem).forEach(field => {
            trItem[field] && (reqTrItem[field] = trItem[field]);
        });
        TR_data.push(reqTrItem);
    });
    return TR_data;
}

export function getInputValues({name, value, haveChanges}) {
   //console.log(name, value, haveChanges)
    const {isEditing, fieldsData} = this.state;
    if ((name === 'latitude') && value && (value < -90 || value > 90)) {
        return;
    }
    if ((name === 'longitude') && value && (value < -180 || value > 180)) {
        return;
    }
    if (isEditing && name !== 'repeatPassword') {
        haveChanges
            ? this.updatedDataMap.set(name, value)
            : this.updatedDataMap.delete(name);
    }
    this.setState({
        fieldsData: {
            ...fieldsData,
            [name]: value,
        }
    })
   // console.log("updatedDataMap", this.updatedDataMap)
}

export function getTranslatableInputValues ({ name, value, haveChanges }) {
    // console.log(name, value, haveChanges)
    const { fieldsData, isEditing, languageTab } = this.state;
    const { translations } = fieldsData;
    if (name === 'slug') {
        value = value.replace(/\\|\/| /gi, '-');
    }
    if (isEditing) {
        haveChanges
            ? this.updatedDataMap.set(name + '_' + languageTab, value)
            : this.updatedDataMap.delete(name + '_' + languageTab);
    }
    this.setState({
        fieldsData: {
            ...fieldsData,
            translations: {
                ...translations,
                [languageTab]: {
                    ...translations[languageTab],
                    [name]: value
                }
            }
        }
    })
    // console.log("updatedDataMap", this.updatedDataMap)
}

export const getAppUrl = (androidUrl, iosUrl, webUrl) => {
    let url = webUrl;
    const mobileOs = getMobileOS();
    if (mobileOs === 'unknown') {
        const browserType = getBrowserType();
        if (browserType === 'Safari') {
            url = iosUrl;
        }
    } else if (mobileOs === 'iOS') {
        url = iosUrl;
    } else if (mobileOs === 'Android') {
        url = androidUrl;
    }
    return url;
};

export const phoneNumberWithSpaces = (price) => {
    return price?.toString()?.replace(/(\d{3})(\d{2})(\d{3})(\d{3})/, '$1 $2 $3 $4');
}