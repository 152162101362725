import {createSelector} from "reselect";
import {initialState} from "../reducers/slider";

const slider_S = state => state.slider;

const creatorFn = selectorKey => {
    return createSelector(slider_S, data => data[selectorKey])
};

export const sliderSelectors = state => {
    const sData = {};

    Object.keys(initialState).forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};