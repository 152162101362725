import {SERVICES_CONSTS} from "../constants";

export const initialState = {
    servicesList: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SERVICES_CONSTS.GET_SERVICES_LIST:
            return {
                servicesList: action.payload
            };
        case SERVICES_CONSTS.CREATE_SERVICE:
            return {
                servicesList: [action.payload].concat(state.servicesList)
            };
        case SERVICES_CONSTS.UPDATE_SERVICE_BY_ID:
            return {
                servicesList: state.servicesList.map(service =>
                    service.id === action.payload.id ? action.payload : service
                )
            };
        case SERVICES_CONSTS.DELETE_SERVICE_BY_ID:
            return {
                servicesList: state.servicesList.filter(service => service.id !== action.payload)
            };
        case SERVICES_CONSTS.DELETE_SERVICE_BLOCK_BY_ID:
            return {
                servicesList: state.servicesList.map((service, index) => {
                    return service.id === action.payload.serviceId ? {
                        ...service,
                        blocks: service.blocks.filter(block => block.id !== action.payload.id)
                    } : service
                })
            };
        default:
            return state;
    }
}