import {PROJECTS_CONSTS, SERVICES_CONSTS} from "../constants";
import {request} from "../api";
import {_urlProjects} from "../api";
import swal from "sweetalert";
import {history} from "../../configs/history";

export const GetProjects = () => {
    const requestData = {
        url: _urlProjects,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: PROJECTS_CONSTS.GET_PROJECTS_LIST,
                    payload: res.data
                })
            })
    }
};

export const GetProjectsById = (id) => {
    const requestData = {
        url: `${_urlProjects}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: PROJECTS_CONSTS.GET_PROJECT_BY_ID,
                    payload: res.data
                })
            })
    }
};

export const CreateProject = data => {
    const requestData = {
        url: _urlProjects,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: PROJECTS_CONSTS.CREATE_PROJECTS,
                    payload: res.data
                })
                history.push('/admin/projects')
            })
    }
};

export const UpdateProjectsPositions = items => {
    const requestData = {
        url: _urlProjects,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PROJECTS_CONSTS.UPDATE_PROJECTS_POSITIONS
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Պրոեկտը հաջողոըթյամբ թարմացվեց!",
                    icon: "success",
                    button: "Ok"
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const UpdateProject = (id, data) => {
    const requestData = {
        url: `${_urlProjects}/${id}`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: PROJECTS_CONSTS.UPDATE_PROJECTS_BY_ID,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Պրոեկտը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                }).then(()=>{
                    history.push('/admin/projects')
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Ok"
                });
            })
    }
};

export const DeleteProject = id => {
    const requestData = {
        url: `${_urlProjects}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PROJECTS_CONSTS.DELETE_PROJECTS_BY_ID,
                    payload: id
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Պրոեկտը հաջողությամբ ջնջվեց!",
                    icon: "success",
                    button: "Ok",
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

/*----------- Blocks -------------*/

export const CreateProjectBlock = (data, serviceId) => {
    const requestData = {
        url: `${_urlProjects}/block`,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                history.push(`/admin/edit-project/${serviceId}`)
            })
    }
};

export const UpdateProjectBlock = (id, data) => {
    const requestData = {
        url: `${_urlProjects}/block/${id}`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SERVICES_CONSTS.UPDATE_SERVICE_BY_ID,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Ծառայությունը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                }).then(() => {
                    history.push('/admin/projects')
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Ok"
                });
            })
    }
};

export const UpdateProjectsBlockPositions = items => {
    const requestData = {
        url: `${_urlProjects}/block`,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                swal({
                    title: "Հաջողվեց",
                    text: "Բաժինը հաջողոըթյամբ թարմացվեց!",
                    icon: "success",
                    button: "Ok"
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const DeleteProjectBlock = (id, serviceId) => {
    const requestData = {
        url: `${_urlProjects}/block/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SERVICES_CONSTS.DELETE_SERVICE_BLOCK_BY_ID,
                    payload: {id, serviceId}
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Բաժինը հաջողությամբ ջնջվեց!",
                    icon: "success",
                    button: "Ok",
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};