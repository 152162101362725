export const StaticTextFields = {
    "hy": {
        "about-title-start": "Going the extra mile to make",
        "about-title-blue": "our customers",
        "about-title-end": "happy.",
        "about-list-1": "Modern architectural style.",
        "about-list-2": "You have a business with 12% investment",
        "about-list-3": "Served parkings and game zone",
        "about-projects-count": "100+",
        "about-projects-title": "Completed projects",
        "about-members-count": "30",
        "about-members-title": "Team members",
        "about-experience-count": "6+",
        "about-experience-title": "Years of experience",
        "categories-title": "Why choose Solidy",
        "categories-description": "Full-service mobile, web and custom software development company.",
        "services-title": "Our services",
        "services-description": "Proudly providing result-driven solutions, for success-driven teams.",
        "services-contact-question": "Need advice?",
        "services-contact-button": "Talk to our experts",
        "ranges-title": "Tech rangers shaping the future.",
        "ranges-description": "With every project developed, the team grows professionally and acquires new skills.",
        "ranges-percent-1": "40",
        "ranges-text-1": "Education",
        "ranges-title-1": "Master's",
        "ranges-percent-2": "100",
        "ranges-text-2": "Education",
        "ranges-title-2": "Bachelor's",
        "ranges-percent-3": "30",
        "ranges-text-3": "Seniority",
        "ranges-title-3": "Senior",
        "ranges-percent-4": "40",
        "ranges-text-4": "Seniority",
        "ranges-title-4": "Middle",
        "ranges-percent-5": "30",
        "ranges-text-5": "Seniority",
        "ranges-title-5": "Junior",
        "ranges-contact-text": "Let's shape the future together",
        "ranges-contact-button": "Join our team",
        "projects-title": "Our Projects",
        "projects-description": "Turning your ambitious ideas into engaging experiences.",
        "contacts-title": "Got an idea? Let’s make it a reality!",
        "contacts-description": "Share your details and have a meeting with our expert consultant.",
        "contacts-email-label": "Your Email",
        "contacts-email-placeholder": "example@inbox.com",
        "contacts-feedback-topic-label": "Feedback topic",
        "contacts-feedback-topic-placeholder": "Please select an option",
        "contacts-name-label": "Subject",
        "contacts-name-placeholder": "Let us know how we can help you",
        "contacts-description-label": "Full description",
        "contacts-description-placeholder": "Please include as much information as possible",
        "contacts-send-message-button": "Send Message",
        "contacts-success-message": "Your message are sent",
        "contacts-info-address-description": "Address:",
        "contacts-info-phone-description": "Phone number:",
        "contacts-info-email-description": "Email:",
        "contacts-page-title": "Contacts Solidy:",
        "main-title": "We run the codes you run the ",
        "main-typing-text-first": "business.",
        "main-typing-text-second": "for success.",
        "main-typing-text-third": "to the future.",
        "main-description": "Just a step away from your innovation-infused digital transformation.",
        "main-question-title": "Got an idea?",
        "main-contact-button": "Let's Talk",
        "main-paragraph-title": "Extraordinary people creating breakthrough innovations.",
        "main-paragraph-description-1": "Solidy is a full-service mobile, web, and custom software development company, with clients ranging from large corporations to funded startups.",
        "main-paragraph-description-2": "With over 100 successfully launched projects, we have expertise across a wide range of industries, such as, e-commerce, social platforms, cashback services and fintech solutions.",
        "footer-title": "SUBSCRIBE TO OUR NEWSLETTER",
        "footer-description": "The latest Solidy news, articles, and resources, sent straight to your inbox every month.",
        "footer-subscribe-placeholder": "Your email address",
        "footer-col-1-title": "Company",
        "footer-col-2-title": "Services",
        "footer-col-3-title": "Products",
        "footer-col-4-title": "Contacts",
        "footer-col-5-title": "Follow us",
        "vacancies-contacts-section-title": "Working conditions Vacancies",
        "vacancies-contacts-section-description": "is a comfortable place to work, but only for those who are willing to develop. Working with us involves a high pace, complex projects, and strict product requirements. These are the values that make us the best digital production company on the market. projects, and strict product is the requirements.",
        "vacancies-contacts-section-btn": "Contact us",
        "employment_terms_full": "Parmanent",
        "employment_terms_part": "Temporary",
        "employment_terms_project": "Contract",
        "employment_terms_probation": "Probationary period",
        "schedules_full_day": "Full time",
        "schedules_full_remote": "Online",
        "schedules_full_flexible": "Flexible",
        "levels_junior": "Junion",
        "levels_middle": "Middle",
        "levels_senior": "Senior"
    },
    "ru": {
        "about-title-start": "Going the extra mile to make",
        "about-title-blue": "our customers",
        "about-title-end": "happy.",
        "about-list-1": "Modern architectural style.",
        "about-list-2": "You have a business with 12% investment",
        "about-list-3": "Served parkings and game zone",
        "about-projects-count": "100+",
        "about-projects-title": "Completed projects",
        "about-members-count": "30",
        "about-members-title": "Team members",
        "about-experience-count": "6+",
        "about-experience-title": "Years of experience",
        "categories-title": "Why choose Solidy",
        "categories-description": "Full-service mobile, web and custom software development company.",
        "services-title": "Our services",
        "services-description": "Proudly providing result-driven solutions, for success-driven teams.",
        "services-contact-question": "Need advice?",
        "services-contact-button": "Talk to our experts",
        "ranges-title": "Tech rangers shaping the future.",
        "ranges-description": "With every project developed, the team grows professionally and acquires new skills.",
        "ranges-percent-1": "40",
        "ranges-text-1": "Education",
        "ranges-title-1": "Master's",
        "ranges-percent-2": "100",
        "ranges-text-2": "Education",
        "ranges-title-2": "Bachelor's",
        "ranges-percent-3": "30",
        "ranges-text-3": "Seniority",
        "ranges-title-3": "Senior",
        "ranges-percent-4": "40",
        "ranges-text-4": "Seniority",
        "ranges-title-4": "Middle",
        "ranges-percent-5": "30",
        "ranges-text-5": "Seniority",
        "ranges-title-5": "Junior",
        "ranges-contact-text": "Let's shape the future together",
        "ranges-contact-button": "Join our team",
        "projects-title": "Our Projects",
        "projects-description": "Turning your ambitious ideas into engaging experiences.",
        "contacts-title": "Got an idea? Let’s make it a reality!",
        "contacts-description": "Share your details and have a meeting with our expert consultant.",
        "contacts-email-label": "Your Email",
        "contacts-email-placeholder": "example@inbox.com",
        "contacts-feedback-topic-label": "Feedback topic",
        "contacts-feedback-topic-placeholder": "Please select an option",
        "contacts-name-label": "Subject",
        "contacts-name-placeholder": "Let us know how we can help you",
        "contacts-description-label": "Full description",
        "contacts-description-placeholder": "Please include as much information as possible",
        "contacts-send-message-button": "Send Message",
        "contacts-success-message": "Your message are sent",
        "contacts-info-address-description": "Address:",
        "contacts-info-phone-description": "Phone number:",
        "contacts-info-email-description": "Email:",
        "contacts-page-title": "Contacts Solidy:",
        "main-title": "We run the codes you run the ",
        "main-typing-text-first": "business.",
        "main-typing-text-second": "for success.",
        "main-typing-text-third": "to the future.",
        "main-description": "Just a step away from your innovation-infused digital transformation.",
        "main-question-title": "Got an idea?",
        "main-contact-button": "Let's Talk",
        "main-paragraph-title": "Extraordinary people creating breakthrough innovations.",
        "main-paragraph-description-1": "Solidy is a full-service mobile, web, and custom software development company, with clients ranging from large corporations to funded startups.",
        "main-paragraph-description-2": "With over 100 successfully launched projects, we have expertise across a wide range of industries, such as, e-commerce, social platforms, cashback services and fintech solutions.",
        "footer-title": "SUBSCRIBE TO OUR NEWSLETTER",
        "footer-description": "The latest Solidy news, articles, and resources, sent straight to your inbox every month.",
        "footer-subscribe-placeholder": "Your email address",
        "footer-col-1-title": "Company",
        "footer-col-2-title": "Services",
        "footer-col-3-title": "Products",
        "footer-col-4-title": "Contacts",
        "footer-col-5-title": "Follow us",
        "vacancies-contacts-section-title": "Working conditions Vacancies",
        "vacancies-contacts-section-description": "is a comfortable place to work, but only for those who are willing to develop. Working with us involves a high pace, complex projects, and strict product requirements. These are the values that make us the best digital production company on the market. projects, and strict product is the requirements.",
        "vacancies-contacts-section-btn": "Contact us",
        "employment_terms_full": "Parmanent",
        "employment_terms_part": "Temporary",
        "employment_terms_project": "Contract",
        "employment_terms_probation": "Probationary period",
        "schedules_full_day": "Full time",
        "schedules_full_remote": "Online",
        "schedules_full_flexible": "Flexible",
        "levels_junior": "Junion",
        "levels_middle": "Middle",
        "levels_senior": "Senior"
    },
    "en": {
        "about-title-start": "Going the extra mile to make",
        "about-title-blue": "our customers",
        "about-title-end": "happy.",
        "about-list-1": "Modern architectural style.",
        "about-list-2": "You have a business with 12% investment",
        "about-list-3": "Served parkings and game zone",
        "about-projects-count": "100+",
        "about-projects-title": "Completed projects",
        "about-members-count": "30",
        "about-members-title": "Team members",
        "about-experience-count": "6+",
        "about-experience-title": "Years of experience",
        "categories-title": "Why choose Solidy",
        "categories-description": "Full-service mobile, web and custom software development company.",
        "services-title": "Our services",
        "services-description": "Proudly providing result-driven solutions, for success-driven teams.",
        "services-contact-question": "Need advice?",
        "services-contact-button": "Talk to our experts",
        "ranges-title": "Tech rangers shaping the future.",
        "ranges-description": "With every project developed, the team grows professionally and acquires new skills.",
        "ranges-percent-1": "40",
        "ranges-text-1": "Education",
        "ranges-title-1": "Master's",
        "ranges-percent-2": "100",
        "ranges-text-2": "Education",
        "ranges-title-2": "Bachelor's",
        "ranges-percent-3": "30",
        "ranges-text-3": "Seniority",
        "ranges-title-3": "Senior",
        "ranges-percent-4": "40",
        "ranges-text-4": "Seniority",
        "ranges-title-4": "Middle",
        "ranges-percent-5": "30",
        "ranges-text-5": "Seniority",
        "ranges-title-5": "Junior",
        "ranges-contact-text": "Let's shape the future together",
        "ranges-contact-button": "Join our team",
        "projects-title": "Our Projects",
        "projects-description": "Turning your ambitious ideas into engaging experiences.",
        "contacts-title": "Got an idea? Let’s make it a reality!",
        "contacts-description": "Share your details and have a meeting with our expert consultant.",
        "contacts-email-label": "Your Email",
        "contacts-email-placeholder": "example@inbox.com",
        "contacts-feedback-topic-label": "Feedback topic",
        "contacts-feedback-topic-placeholder": "Please select an option",
        "contacts-name-label": "Subject",
        "contacts-name-placeholder": "Let us know how we can help you",
        "contacts-description-label": "Full description",
        "contacts-description-placeholder": "Please include as much information as possible",
        "contacts-send-message-button": "Send Message",
        "contacts-success-message": "Your message are sent",
        "contacts-info-address-description": "Address:",
        "contacts-info-phone-description": "Phone number:",
        "contacts-info-email-description": "Email:",
        "contacts-page-title": "Contacts Solidy:",
        "main-title": "We run the codes you run the ",
        "main-typing-text-first": "business.",
        "main-typing-text-second": "for success.",
        "main-typing-text-third": "to the future.",
        "main-description": "Just a step away from your innovation-infused digital transformation.",
        "main-question-title": "Got an idea?",
        "main-contact-button": "Let's Talk",
        "main-paragraph-title": "Extraordinary people creating breakthrough innovations.",
        "main-paragraph-description-1": "Solidy is a full-service mobile, web, and custom software development company, with clients ranging from large corporations to funded startups.",
        "main-paragraph-description-2": "With over 100 successfully launched projects, we have expertise across a wide range of industries, such as, e-commerce, social platforms, cashback services and fintech solutions.",
        "footer-title": "SUBSCRIBE TO OUR NEWSLETTER",
        "footer-description": "The latest Solidy news, articles, and resources, sent straight to your inbox every month.",
        "footer-subscribe-placeholder": "Your email address",
        "footer-col-1-title": "Company",
        "footer-col-2-title": "Services",
        "footer-col-3-title": "Products",
        "footer-col-4-title": "Contacts",
        "footer-col-5-title": "Follow us",
        "vacancies-contacts-section-title": "Working conditions Vacancies",
        "vacancies-contacts-section-description": "is a comfortable place to work, but only for those who are willing to develop. Working with us involves a high pace, complex projects, and strict product requirements. These are the values that make us the best digital production company on the market. projects, and strict product is the requirements.",
        "vacancies-contacts-section-btn": "Contact us",
        "employment_terms_full": "Parmanent",
        "employment_terms_part": "Temporary",
        "employment_terms_project": "Contract",
        "employment_terms_probation": "Probationary period",
        "schedules_full_day": "Full time",
        "schedules_full_remote": "Online",
        "schedules_full_flexible": "Flexible",
        "levels_junior": "Junion",
        "levels_middle": "Middle",
        "levels_senior": "Senior"
    }
}
