import {MENU_CONSTS} from "../constants";

export const initialState = {
    menuItemList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MENU_CONSTS.GET_MENU_LIST:
            return {
                ...state,
                menuItemList:  action.payload,
            };
        case MENU_CONSTS.UPDATE_MENU_BY_ID:
            return {
                menuItemList: state.menuItemList.map(menu =>
                    menu.id === action.payload.id ? action.payload : menu
                )
            };
        case MENU_CONSTS.DELETE_MENU_BY_ID:
            return {
                menuItemList: state.menuItemList.filter(menu => menu.id !== action.payload)
            };
        default:
            return state;
    }
}
