// Import packages
import React, {Component} from "react";
import {connect} from 'react-redux';

//Import styles
import "../../assets/styles/front-styles/front-layout.scss";

//Import images

//Import components
import Main from "./main/Main";
import Categories from "./categories/Categories";
import Services from "./services/Services";
import Contacts from "../Contacts";
import Projects from "../Projects";
import About from "./about/About";


// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetHeight} from "../../redux/actions/front";

class Homepage extends Component {
    constructor() {
        super();
        this.section0 = React.createRef();
        this.section1 = React.createRef();
        this.section2 = React.createRef();
        this.section3 = React.createRef();
        this.section4 = React.createRef();
        this.section5 = React.createRef();

        this.scrollToSection = this.scrollToSection.bind(this);
    }

    componentDidMount() {
        const pagesHeights = [
            this.section0?.current?.clientHeight,
            this.section1?.current?.clientHeight,
            this.section2?.current?.clientHeight,
            this.section3?.current?.clientHeight,
            this.section4?.current?.clientHeight,
            this.section5?.current?.clientHeight,
        ];
        this.props.GetHeight(pagesHeights)
    }

    scrollToSection(index) {
        let height = 0;
        if (index >= 0 && index <= 5) {
            for (let i = 0; i < index; i++) {
                height += this.props.heights?.[i];
            }

            window.scrollTo({
                top: index === 1 ? height : height ,
                behavior: "smooth"
            });
        }
    };

    render() {
        return <div className={'front-layout-wrapper'}>
            <Main refProp={this.section0}
                  scrollToSection={() => this.scrollToSection(5)}/>
            <About refProp={this.section1}/>
            <Services refProp={this.section2}
                      scrollToSection={() => this.scrollToSection(5)}/>
            <Categories refProp={this.section3}/>
            <Projects refProp={this.section4}/>
            <Contacts refProp={this.section5}/>
        </div>
    }
}

const mapDispatchToProps = {
    GetHeight
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
