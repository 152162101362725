// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

//Import assets
import "./projects.scss";
import {ReactComponent as ArrowIcon} from "../../assets/images/front/icons/ic_arrow_top.svg";

//Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";
import {PROJECT_TYPES} from "../../constants/constTypes";
import {GetProjectsWeb} from "../../redux/actions/front";
import {Link} from "react-router-dom";

function Projects(props) {
    const [selectedType, setSelectedType] = useState(PROJECT_TYPES.ALL.key)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    function filterProject(type) {
        props.GetProjectsWeb({
            data: props.selectedLanguage.code,
            type: type === PROJECT_TYPES.ALL.key ? null : type
        })
        setSelectedType(type)
    }

    return (
        <section className="projects_page" ref={props.refProp}>
            <div className="page-title">{props?.translation?.['projects-title']}</div>
            <div className="page-description">{props?.translation?.['projects-description']}</div>

            <div className="project-types-list">
                {Object.keys(PROJECT_TYPES)?.map(key => {
                    return <div className={`list-item ${selectedType === key ? 'selected': ''}`} key={key.id} onClick={() => filterProject(key)}>
                        {PROJECT_TYPES[key]?.name}
                    </div>
                })}
            </div>

            <div className="projects-content">
                {props?.projects?.map(item => {
                    return <div className="project" key={item.id} id={item?.shortName}>
                        <div className="project__content">
                            <div className="title">{item?.title}</div>
                            <div className="description">{item.description}</div>
                            <Link to={`/projects/${item.id}`} className="read_more">Read More <ArrowIcon/></Link>
                        </div>
                        <div className="project__img">
                            <div className="inner">
                                <img src={generateFileMediaUrl(item?.logoPath?.path)} alt=""/>
                            </div>
                            <div className={"features-wrapper"}>
                                {!!item?.tags?.length && item?.tags.map((tag, index) => {
                                    return <div className="feature-item" key={tag + index}>
                                        {tag}
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </section>
    );
}

const mapDispatchToProps = {
    GetProjectsWeb
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects);