// Import packages
import React from 'react';
import {connect} from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import {CountUp} from "use-count-up";

//Import styles
import "./about.scss";

//Import images
import {generateFileMediaUrl} from "../../../utils/generateMemberMediaUrl";

//Import components
import {mapStateToProps} from "../../../redux/mapStateToProps";

function About(props) {
    return <section className="about" ref={props.refProp}>
        <img src={`https://static.4steps.am/resources/about.jpeg`}
             className={'about__img'} alt=""/>
        <div className="about__content">
            <div className="about__content__title">
                {props.translation['about-title-start']}
                <span className="blue-text">{props.translation['about-title-blue']}</span>&nbsp;
                {props.translation['about-title-end']}
            </div>
            <ul className="about__content__list">
                <li>{props.translation['about-list-1']} </li>
                <li>{props.translation['about-list-2']} </li>
                <li>{props.translation['about-list-3']} </li>
            </ul>
            <div className="about__content__quantity">
                <div className="quantity-item">
                    <VisibilitySensor>{({isVisible}) => {
                        return (
                            <div className="count">
                                <CountUp isCounting={isVisible} start={0}
                                         end={Number(props.translation['about-projects-count'] || 0)}
                                         duration={1} delay={4} easing="linear"/>
                                +
                            </div>
                        );
                    }}</VisibilitySensor>
                    <div className="description">{props.translation['about-projects-title']} </div>
                </div>
                <div className="quantity-item">
                    <VisibilitySensor>{({isVisible}) => {
                        return (
                            <div className="count">
                                <CountUp isCounting={isVisible} start={0}
                                         end={Number(props.translation['about-members-count'])}
                                         duration={1} delay={4} easing="linear"/>
                            </div>
                        );
                    }}</VisibilitySensor>
                    <div className="description">{props.translation['about-members-title']} </div>
                </div>
                <div className="quantity-item">
                    <VisibilitySensor>{({isVisible}) => {
                        return (
                            <div className="count">
                                <CountUp isCounting={isVisible} start={0}
                                         end={Number(props.translation['about-experience-count'])}
                                         duration={1} delay={4} easing="linear"/>+
                            </div>
                        );
                    }}</VisibilitySensor>
                    <div className="description">{props.translation['about-experience-title']} </div>
                </div>
            </div>
        </div>
    </section>
}

export default connect(mapStateToProps)(About);