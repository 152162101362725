// Import packages
import React from 'react';
import {connect} from "react-redux";

//Import styles
import "./categories.scss";

//Import components
import {mapStateToProps} from "../../../redux/mapStateToProps";
import {generateFileMediaUrl} from "../../../utils/generateMemberMediaUrl";
import Ranges from "../ranges/Ranges";

function Categories(props) {
    return (
        <section className="categories" ref={props.refProp}>
            <div className="categories-list">
                <h2 className="section-title">{props?.translation && props.translation['categories-title']}</h2>
                <div
                    className="section-description">{props?.translation && props.translation['categories-description']}</div>
                <div className="categories-item-wrapper">
                    {props?.categories?.map(item => {
                        return <div className="categories-item" key={item.id}>
                            <img src={generateFileMediaUrl(item?.logoPath?.path)} alt=""/>
                            <p className="item-title">{item.title}</p>
                            <p className="item-description">{item.description}</p>
                        </div>
                    })}
                </div>
            </div>
            <Ranges/>
        </section>
    );
}

export default connect(mapStateToProps)(Categories);