import {STATIC_TEXTS_CONSTS} from "../constants";
import {request} from "../api/request";
import swal from "sweetalert";
import { _urlStaticTexts} from "../api";

export const GetStaticTexts = () => {
    const requestData = {
        url: _urlStaticTexts,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: STATIC_TEXTS_CONSTS.GET_STATIC_TEXTS,
                    payload: res.data
                })
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const UpdateStaticTexts = (data,code, key) => {
    const requestData = {
        url: `${_urlStaticTexts}/${code}.${key}`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: STATIC_TEXTS_CONSTS.UPDATE_STATIC_TEXTS,
                    payload: {
                        key,
                        code,
                        value: data.value
                    }
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Ստատիկ տեքստը հաջողությամբ փոփոխվեց!",
                    icon: "success",
                    button: "Ok"
                });
            })
            .catch(() => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};
