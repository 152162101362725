export const STATUS_TYPES = {
   'WAITING': 'Սպասում է',
   'COMPLETED': 'Ավարտված',
};
export const HISTORY_SEND_FROM_TYPES = {
   'WEB': 'Կայք',
   'MOBILE': 'Հեռախոս',
};

export const MEDIA_MODAL_TYPES = {
    MEDIA_MAIN: 'MEDIA_MAIN',
    MEDIA_ARRAY: 'MEDIA_ARRAY',
};

export const MEDIA_TYPES = {
    FILE: 'FILE',
    FOLDER: 'FOLDER',
};

export const UPLOAD_TYPES = {
    SELF: 'SELF',
    URL: 'URL',
};

export const EMPLOYMENT_TERMS_TYPES = {
    FULL: 'employment_terms_full',
    PART: 'employment_terms_part',
    PROJECT: 'employment_terms_project',
    PROBATION: 'employment_terms_probation',
};

export const SCHEDULES_TYPES = {
    FULL_DAY: 'schedules_full_day',
    REMOTE: 'schedules_full_remote',
    FLEXIBLE: 'schedules_full_flexible',
};

export const LEVELS_TYPES = {
    JUNIOR: 'levels_junior',
    MIDDLE: 'levels_middle',
    SENIOR: 'levels_senior',
};


export const HISTORY_TYPES = {
    ADMIN_TO_COMPANY: 'ADMIN_TO_COMPANY',
    ADMIN_TO_USER: 'ADMIN_TO_USER',
    FILLED_ACCOUNT_FROM_CARD: 'FILLED_ACCOUNT_FROM_CARD',
    FILLED_ACCOUNT_FROM_ATM: 'FILLED_ACCOUNT_FROM_ATM',
    MONEY_TO_OR_FROM_FRIEND: 'MONEY_TO_OR_FROM_FRIEND',
    FUEL_TO_OR_FROM_FRIEND: 'FUEL_TO_OR_FROM_FRIEND',
    GET_FUEL_VIA_ACCOUNT: 'GET_FUEL_VIA_ACCOUNT',
    GET_FUEL_VIA_CARD: 'GET_FUEL_VIA_CARD',
    GET_FUEL_FROM_COMPANY: 'GET_FUEL_FROM_COMPANY',
    SPENT_COMPANY_FUEL: 'SPENT_COMPANY_FUEL',
    SPENT_PERSONAL_FUEL: 'SPENT_PERSONAL_FUEL'

};

export const VACANCIES_CATEGORY_TYPES = {
    MOBILE: {
        key: 'MOBILE',
        name: 'Mobile'
    },
    // DEVELOPMENT: {
    //     key: 'DEVELOPMENT',
    //     name: 'Development'
    // },
    DESIGN: {
        key: 'DESIGN',
        name: 'Design'
    },
    MANAGEMENT: {
        key: 'MANAGEMENT',
        name: 'Management'
    },
};

export const PROJECT_TYPES = {
    ALL: {
        key: 'ALL',
        name: 'All'
    },
    MOBILE: {
        key: 'MOBILE',
        name: 'Mobile'
    },
    WEB: {
        key: 'WEB',
        name: 'Web'
    }
};