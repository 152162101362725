import {CAREERS_CONSTS} from "../constants";

export  const initialState = {
    careersData: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CAREERS_CONSTS.GET_CAREERS_LIST:
            return {
                careersData: action.payload
            };
        default:
            return state;
    }
}