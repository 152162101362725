// Import packages
import React, {PureComponent} from "react";

// Import styles
import "../assets/styles/front-styles/file-uploader.scss";
import {ReactComponent as ClearIcon} from '../assets/images/front/vacancies/ic_clear.svg';
import {ReactComponent as FileIcon} from '../assets/images/front/vacancies/ic_file.svg';

// Import components

//Import Images

//import utils
import {hasExtension} from "../utils/hasExtension";
import {MAX_FILE_SIZE} from "../constants/acceptedTypes";


export default class FileUploader extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            file: "",
            fileError: false,
            errorMessage: "",
        };
        this.imageSelector = React.createRef();
        this.deleteFile = this.deleteFile.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Read the image via FileReader API and save image result in state.
            reader.onload = function (e) {
                let dataURL = e.target.result;
                resolve({file, dataURL});
            };
            reader.onerror = function (e) {
                //console.log(e);
                reject(e);
            }
            reader.readAsDataURL(file);
        });
    }

    handleImageChange(e, dropFile) {
        //console.log('change', dropFile);
        let file = e && e.target && e.target.files && e.target.files[0];
        if (file) {
            e.target.value = null;
        } else {
            file = dropFile
        }
        //console.log('selected-file', file);

        if (file) {
            if (!hasExtension(file.name)) {
                this.setState({
                    fileError: true,
                    errorMessage: 'Not Supported file format!'
                });
                return;
            }
            if (file.size > MAX_FILE_SIZE) {
                this.setState({
                    fileError: true,
                    errorMessage: `File size is too big ${Math.floor(MAX_FILE_SIZE / 1048576)}mb`
                });
                return;
            }

            // console.log("file", file);

            this.readFile(file)
                .then(res => {
                    // console.log("res", res);
                    this.setState({
                        // file: res,
                        fileError: false,
                        errorMessage: "",
                    })
                    this.props.getValue({name: "file", value: res})
                });
        }
    }

    deleteFile() {
        this.setState({
            file: "",
            fileError: false,
            errorMessage: "",
        })
        this.props.getValue({name: "file", value: ""})
    }

    static dragEnter(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    static dragOver(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    onDrop(e) {
        e.stopPropagation();
        e.preventDefault();
        let dt = e.dataTransfer;
        let file = dt?.files?.[0];
        this.handleImageChange(null, file);
    }

    render() {
        let {errorMessage} = this.state;
        let {error, file} = this.props;
        return <div className={`post-image-uploader`}>
            {
                file ? <div className={'image-wrapper'}>
                        <div className={'upload-box'}>
                            <div className={"box-title"}><FileIcon className={"file-icon"} title={''}/> {file?.file?.name} <ClearIcon  className={"clear-icon"} title={''} onClick={this.deleteFile}/></div>
                            <p className={"box-text"}>Max. file size: 4MB (pdf, doc, docx)</p>
                        </div>
                    </div> :
                    <>
                        <div className={`crop-img-selector ${error ? "invalid" : ""}`}
                             onDragEnter={FileUploader.dragEnter}
                             onDragOver={FileUploader.dragOver}
                             onDrop={this.onDrop}
                             onClick={() => this.imageSelector.current.click()}>
                            <div className={'upload-box'}>
                                <div className={"box-title"}>Drop your resume here or <span>browse</span></div>
                                <p className={"box-text"}>Max. file size: 4MB (pdf, doc, docx)</p>
                            </div>
                            <div className={'error-wrapper'}>
                                {errorMessage}
                            </div>
                        </div>
                        <input type="file" accept=".doc, .docx, .pdf" ref={this.imageSelector}
                               onChange={this.handleImageChange}/>
                    </>
            }
        </div>
    }
}
