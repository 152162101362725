// Import packages
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import isEmail from "validator/lib/isEmail";

//Import styles
import "../assets/styles/front-styles/contact.scss";

//Import components
import {InputGroup} from "../admin-components/uiElements/inputGroup";
import {mapStateToProps} from "../redux/mapStateToProps";
import {SendContactsEmail, GetFeedbackTopicsWeb} from "../redux/actions/front";
import {LinkButton} from "../admin-components/buttons/buttons";

function Contacts(props) {
    const [contact, setContact] = useState({
        email: '',
        feedbackTopic: undefined,
        name: '',
        message: '',
    });
    const [errors, setErrors] = useState({
        email: false,
        feedbackTopic: false,
        name: false,
        message: false,
    });
    const [requestLoading, setRequestLoading] = useState(false);
    const [message, setMessage] = useState('');


    useEffect(() => {
        props.GetFeedbackTopicsWeb();
    }, []);

    function getInputValues({name, value,}) {
        setContact({
            ...contact,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    function validate() {
        const errors = {};
        let result = true;
        Object.keys(contact).forEach(key => {
            if (!contact[key] || (key === 'email' && !isEmail(contact?.email))
            ) {
                errors[key] = true;
                result = false;
            }
        })
        setErrors(errors);
        return result;
    }

    async function sendMessage() {
        await setRequestLoading(true);

        if (validate()) {
            props.SendContactsEmail(contact).then(() => {
                setContact({
                    email: '',
                    feedbackTopic: undefined,
                    name: '',
                    message: '',
                });
                setErrors({
                    email: false,
                    feedbackTopic: false,
                    name: false,
                    message: false,
                });
                setMessage(props?.translation && props.translation['contacts-success-message']);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }).catch(() => {

            }).finally(() => {
                setRequestLoading(false)
            })
        } else {
            setRequestLoading(false)
        }
    }

    return (
        <section className="contact" ref={props.refProp}>
            <h2 className="section-title">{props?.translation?.['contacts-title']}</h2>
            <div className="section-description">{props?.translation?.['contacts-description']}</div>
            <div className="contact-content">
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    name={'email'}
                    value={contact.email}
                    error={errors.email}
                    label={props?.translation && props.translation['contacts-email-label']}
                    placeholder={props?.translation && props.translation['contacts-email-placeholder']}
                    maxLength={50}
                    onChange={getInputValues}
                />
                <InputGroup
                    inputType={'selectCustom'}
                    name={'feedbackTopic'}
                    value={contact.feedbackTopic}
                    error={errors.feedbackTopic}
                    options={props.feedbackList}
                    showArrow={false}
                    optionFilterProp="children"
                    label={props?.translation && props.translation['contacts-feedback-topic-label']}
                    placeholder={props?.translation && props.translation['contacts-feedback-topic-placeholder']}
                    onChange={getInputValues}
                />
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    name={'name'}
                    value={contact.name}
                    error={errors.name}
                    label={props?.translation && props.translation['contacts-name-label']}
                    placeholder={props?.translation && props.translation['contacts-name-placeholder']}
                    maxLength={50}
                    onChange={getInputValues}
                />
                <InputGroup
                    inputType={'textarea'}
                    type={"text"}
                    name={'message'}
                    value={contact.message}
                    error={errors.message}
                    label={props?.translation && props.translation['contacts-description-label']}
                    placeholder={props?.translation && props.translation['contacts-description-placeholder']}
                    maxLength={2000}
                    onChange={getInputValues}
                />
                <div className="success-message">{message}</div>
                <div className="btn-wrapper">
                    <LinkButton
                        loading={requestLoading}
                        className="send-btn"
                        cb={sendMessage}
                        title={props?.translation && props.translation['contacts-send-message-button']}/>
                </div>
                <div className="contact-info">
                    <div><span
                        className="contact-description">{props?.translation && props.translation['contacts-info-address-description']}</span>{props?.contacts?.address}
                    </div>
                    <div><span
                        className="contact-description">{props?.translation && props.translation['contacts-info-phone-description']}</span>{props?.contacts?.phoneNumber}
                    </div>
                    <div><span
                        className="contact-description">{props?.translation && props.translation['contacts-info-email-description']}</span>{props?.contacts?.email}
                    </div>
                </div>
            </div>
        </section>
    );
}

const mapDispatchToProps = {
    SendContactsEmail,
    GetFeedbackTopicsWeb,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);