// import packages
import React from "react";
import {connect} from "react-redux";

// import assets
import './notFound.scss'

// import components
import NavigationBar from "../NavigationBar";
import {mapStateToProps} from "../../redux/mapStateToProps";

function NotFound(props) {

    return <>
        <NavigationBar/>
        <div className="no-result-empty-view">
            <p>Page Not Found</p>
        </div>
    </>
}

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
