import {createSelector} from "reselect";
import {initialState} from "../../reducers/front";

const web_S = state => state.web;

const creatorFn = selectorKey => {
    return createSelector(web_S, data => data[selectorKey])
};

export const webSelectors = state => {
    const sData = {};
    Object.keys(initialState).forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
