import {history} from "../../configs/history";
import {request, _urlLanguages} from "../api";
import {LANGUAGES_CONSTS, UTIL_CONSTS,} from "../constants";
import swal from "sweetalert";

export const GetLanguages = () => {
    const requestData = {
        url: _urlLanguages,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res.data;
                data && await dispatch({
                    type: LANGUAGES_CONSTS.GET_LANGUAGES,
                    payload: data,
                });
            })
    }
};

export const AddLanguage = (lg_data) => {
    const requestData = {
        url: _urlLanguages,
        token: true,
        method: "POST",
        data: lg_data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: LANGUAGES_CONSTS.CREATE_LANGUAGES,
                    payload: data
                });

                swal({
                    title: "Success!",
                    text: "Լեզուն հաջողությամբ ավելացվեց",
                    icon: "success",
                    button: "Ok",
                }).then(() => {
                    history.push('/admin/languages')
                })
            })
    }
};

export const DeleteLanguage = id => {
    const requestData = {
        url:  `${_urlLanguages}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                 dispatch({
                    type: LANGUAGES_CONSTS.DELETE_LANGUAGE,
                    payload: id,
                });
            })
    }
};

export const UpdateLanguage = (data, id) => {
    const requestData = {
        url:  `${_urlLanguages}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING,
        });
        return request(requestData)
            .then(res => {
                dispatch({
                    type: LANGUAGES_CONSTS.UPDATE_LANGUAGE,
                    payload: res.data
                });
                swal({
                    title: "Success",
                    text: "Լեզուն հաջողությամբ թարմացվել է!",
                    icon: "success",
                    button: "Լավ"
                }).then(() => history.push("/admin/languages"));
            }).finally(()=>{
                dispatch({
                    type: UTIL_CONSTS.END_LOADING,
                });
            })
    }
};
