// Import packages
import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';

//Import styles
import "./serviceDetails.scss";

//Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";

function ServiceDetails(props) {

    return (
        <section className="service-details" ref={props.refProp}>
            <div className="page-title">{props?.translation?.['services-title']}</div>
            <div className="page-description">{props?.translation?.['services-description']}</div>

            <div className="services-content">
                <div className="services-list">
                    {props?.services?.map(item => {
                        return <a href={`#${item?.shortName?.trim()}`}
                                  className="list-item" key={item.id}>{item?.shortName}</a>
                    })}
                </div>

                <div className="services-content-list">
                    {props?.services?.map(item => {
                        return <div className="service" key={item.id} id={item?.shortName}>
                            <div className="service__header">
                                <div className="title">{item?.title}</div>
                                <div className="description">{item?.subTitle}</div>
                            </div>
                            <div className="service__blocks">
                                {
                                    item?.blocks?.map(item => {
                                        return <div key={item?.id} className="block">
                                            <div className="service__blocks__images">
                                                {
                                                    item?.mediaArray?.slice(0, 3)?.map(img => {
                                                        return <div
                                                            className={`block_img_wrapper ${item?.mediaArray?.length === 1 ? 'full' : ''}`}
                                                            key={img.id}
                                                            style={{width: `${100 / item?.mediaArray?.length}%`}}>
                                                            <div className="inner">
                                                                <img src={generateImageMediaUrl(img?.path)} alt=""/>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>

                                            <div className="sun-editor-editable service__blocks__description"
                                                 dangerouslySetInnerHTML={{__html: item?.description}}/>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    })}
                </div>
            </div>

        </section>
    );
}

export default connect(mapStateToProps)(ServiceDetails);