// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

//Import assets
import "./projectDetails.scss";

//Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";
import {ClearProjectByIdWeb, GetProjectByIdWeb} from "../../redux/actions/front";
import {useParams} from "react-router-dom";

function ProjectDetails({projectById, GetProjectByIdWeb, selectedLanguage, ClearProjectByIdWeb}) {

    //Hooks
    const params = useParams()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    useEffect(() => {
        GetProjectByIdWeb(params.id, selectedLanguage.code)
            .then(() => {
                setLoading(false)
            })
        return () => {
            ClearProjectByIdWeb()
        }
    }, [])

    return <section className="projects_page">
        <div className="page-title">{projectById?.title}</div>
        <div className="page-description">{projectById?.description}</div>
        {!loading && <div className="projects-img-wrapper">
            <img className="projects-img" src={generateFileMediaUrl(projectById?.logoPath?.path)} alt=""/>
            <div className="project-links">
                {projectById?.webUrl && <a className="link" target={'_blank'} href={projectById?.webUrl}>Web</a>}
                {projectById?.iosUrl && <a className="link" target={'_blank'} href={projectById?.iosUrl}>iOS</a>}
                {projectById?.androidUrl && <a className="link" target={'_blank'} href={projectById?.androidUrl}>Android</a>}
            </div>
        </div>}

        {
            !!projectById?.blocks?.length && projectById?.blocks?.map(item => {
                return <div className="block-wrapper">
                    <img src={generateFileMediaUrl(item?.mediaArray[0]?.path)} alt=""/>
                    <div className="block-content">
                        <div className="block-title">{item?.title}</div>
                        <div className="sun-editor-editable block-description"
                             dangerouslySetInnerHTML={{__html: item?.description}}/>
                    </div>
                </div>
            })
        }


        {!loading && projectById?.logoDetails?.path && <img className="projects-img-wrapper" src={generateFileMediaUrl(projectById?.logoDetails?.path)} alt=""/>}


        <div className="tools-wrapper">
            {
                !!projectById?.tools?.length && projectById?.tools?.map((tool, index) => {
                    return <ul className="tools-item" key={`${tool.title}_${index}`}>
                        <div className="tool-title">{tool.title}</div>
                        {!!tool?.tags?.length && tool?.tags?.map((tag, index) => {
                            return <li className="tag" key={`${tag}_${index}`}>{tag}</li>
                        })}
                    </ul>
                })
            }
        </div>

    </section>
}

const mapDispatchToProps = {
    GetProjectByIdWeb,
    ClearProjectByIdWeb
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);