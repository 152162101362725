// Import packages
import React from "react";
import {Spin, Icon} from "antd";

// Import utils
import {history} from "../../configs/history";

// Import styles
import "../../assets/styles/dataDisplay/buttons.scss";

const spinIcon = <Icon type="loading" style={{fontSize: 16, color: "#fff"}} spin/>;

export function LinkButton(props) {
    const {title, className, link, cb, loading, disabled, } = props;
    return <button className={`link-button ${className ? className : ''}`}
                   disabled={disabled || false}
                   onClick={() => {
                       if (link) {
                           history.push(link)
                       } else {
                           if (!loading && cb) {
                               cb();
                           }
                       }
                   }}>
        {loading ?
            <div className="flex-row"><Spin indicator={spinIcon}/></div> :
            <span className="title">{title}</span>}
    </button>
}
export function EditButton(props) {
    return <button className={`btn editButton ${props.className || ""}`}
                   disabled={props.disabled || false}
                   onClick={() => {
                       props.link && history.push(props.link)
                   }}>
        {props.children}
    </button>
}
