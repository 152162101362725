//--------------------------------------- Solidy-server --------------------------------------------
export const ADMIN_URL =  "https://api.solidy.am/admin/api/v1";
export const ADMIN_FILES_URL =  "https://api.solidy.am/admin";
export const ADMIN_RESOURCES_URL =  "https://static.solidy.am";

export const _urlOauth = ADMIN_URL + "/jwt";
export const _urlMedia = ADMIN_URL + "/files";
export const _urlMenu = ADMIN_URL + "/menu";
export const _urlCategories = ADMIN_URL + "/categories";
export const _urlLanguages = ADMIN_URL + "/languages";
export const _urlSlider = ADMIN_URL + "/slider";
export const _urlContact = ADMIN_URL + "/contacts";
export const _urlFeedbackTopics = ADMIN_URL + "/feedback-topics";
export const _urlServices = ADMIN_URL + "/services";
export const _urlProjects = ADMIN_URL + "/projects";
export const _urlVacancies = ADMIN_URL + "/vacancy";
export const _urlCareers = ADMIN_URL + "/vacancy-home";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlSales = ADMIN_URL + "/emailing";


//--------------------Web Urls-------------------------
export const WEB_URL = "https://api.solidy.am/front/api/v1";

export const _urlLanguageWeb = WEB_URL + "/languages";
export const _urlCategoriesWeb = WEB_URL + "/categories";
export const _urlMenuWeb = WEB_URL + "/menu";
export const _urlSliderWeb = WEB_URL + "/slider";
export const _urlServicesWeb = WEB_URL + "/services";
export const _urlProjectsWeb = WEB_URL + "/projects";
export const _urlContactWeb = WEB_URL + "/contact";
export const _urlFeedbackTopicsWeb = WEB_URL + "/feedback-topics";
export const _urlVacanciesWeb = WEB_URL + "/vacancies";
export const _urlPagesWeb = WEB_URL + "/custom-pages";