import {createSelector} from "reselect";
import {initialState} from "../reducers/emailing";

const emailing_S = state => state.emailing;

const creatorFn = selectorKey => {
    return createSelector(emailing_S, data => data[selectorKey])
};

export const emailingSelectors = state => {
    const sData = {};

    Object.keys(initialState).forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};