import {CAREERS_CONSTS} from "../constants";
import {_urlCareers, request} from "../api";
import swal from "sweetalert";

export const GetCareers = () => {
    const requestData = {
        url: _urlCareers,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: CAREERS_CONSTS.GET_CAREERS_LIST,
                    payload: res.data
                })
            })
    }
};

export const UpdateCareer = (data) => {
    const requestData = {
        url: `${_urlCareers}`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: CAREERS_CONSTS.UPDATE_CAREER_BY_ID,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Կարիերան հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                })
            })
            .catch((err) => {
                swal({
                    title: "Oops!",
                    text: "Ինչ որ բան այն չէ, խնդրում ենք փորձել կրկին",
                    icon: "warning",
                    button: "Ok"
                });
            })
    }
};