import axios from "axios";
import {store} from "../store";
import {history} from "../../configs/history";
import {AUTH_CONSTS, UTIL_CONSTS} from "../constants";
import {RefreshToken, TokenValidation} from "../actions";
import swal from "sweetalert";
import {responseTexts} from "../../constants/responseTexts";

export const request = ({url, method = "GET", data = null, token = false, customHeaders = null,}) => {
    const scope = store.getState() && store.getState().auth && store.getState().auth.scope;

    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "scope": scope
    };

    token && (headers["x-access-token"] = `${store.getState().auth.accessToken}`);
    token && customHeaders && (customHeaders["x-access-token"] = `${store.getState().auth.accessToken}`);

    scope && (headers["scope"] = scope);
    scope && customHeaders && (customHeaders["scope"] = scope);

    const requestData = {
        url: url,
        method: method,
        headers: customHeaders || headers,
    };

    data && (requestData.data = data);
    return axios.request(requestData)
};

export const requestWeb = ({url, method = "GET", languageFlag = false, customHeaders = null, data = null}) => {
    // console.log(customHeaders, 'customHeaders');
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    };
    let language = (store.getState() && store.getState().web && store.getState().web.selectedLanguage
        && store.getState().web.selectedLanguage.code) || 'hy';

    if (languageFlag) {
        headers["Accept-Language"] = language
    }
    const requestData = {
        url,
        method,
        headers: customHeaders || headers,
    };
    if (data) {
        requestData.data = data
    }

    return axios.request(requestData)
};

// log user out when token expires

axios.interceptors.response.use(response => {
        store.dispatch({
            type: UTIL_CONSTS.END_LOADING
        });
        return response;
    },

    async error => {

        const originalRequest = error.config;
        //console.log('originalRequest', originalRequest);
        if (originalRequest.url.slice(-13) === "refresh-token") {
            // if refresh token has been expired
            //console.log('refresh token has been expired');
        } else if (error.response && error.response.status === 401) {
            const accessToken = await RefreshToken();
            // console.log('accessToken', accessToken);
            if (accessToken) {
                originalRequest.headers['authorization'] = 'Bearer ' + accessToken;
                return axios(originalRequest);
            } else {
                !originalRequest.url.includes('authenticate') && swal({
                    title: responseTexts.errorDefaultTitle,
                    text: responseTexts.expired,
                    icon: "warning",
                    button: responseTexts.okButton
                });
                store.dispatch({
                    type: AUTH_CONSTS.LOG_OUT
                });
                history.push("/login");
            }
        }
        if (error.response && error.response.status === 403) {
            TokenValidation()(store.dispatch).then(() => {
                history.push('/dashboard');
            });
        } else {

            if (history?.location?.pathname.startsWith("/admin")) {
                swal({
                    title: responseTexts.errorDefaultTitle,
                    text: responseTexts.errorDefaultText,
                    icon: "warning",
                    button: responseTexts.okButton
                });
            }
        }
        store.dispatch({
            type: UTIL_CONSTS.END_LOADING
        });
        return Promise.reject(error);
    }
);
