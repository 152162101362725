import {UTIL_CONSTS} from "../constants";


export const ToggleLeftSideMenu = () => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.TOGGLE_LEFT_SIDE_MENU
        })
    }
};

export const RedirectFirstTime = () => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.REDIRECT_FIRST_TIME
        })
    }
};