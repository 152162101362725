import {createSelector} from "reselect";
import {initialState} from "../reducers/projects";

const project_S = state => state.projects;

const creatorFn = selectorKey => {
    return createSelector(project_S, data => data[selectorKey])
};

export const projectSelectors = state => {
    const sData = {};

    Object.keys(initialState).forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};