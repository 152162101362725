import {VACANCIES_CONSTS} from "../constants";

export  const initialState = {
    vacanciesList: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case VACANCIES_CONSTS.GET_VACANCIES_LIST:
            return {
                vacanciesList: action.payload
            };
        case VACANCIES_CONSTS.CREATE_VACANCY:
            return {
                vacanciesList: [action.payload].concat(state.vacanciesList)
            };
        case VACANCIES_CONSTS.UPDATE_VACANCY_BY_ID:
            return {
                vacanciesList: state.vacanciesList.map(vacancy =>
                    vacancy.id === action.payload.id ? action.payload : vacancy
                )
            };
        case VACANCIES_CONSTS.DELETE_VACANCY_BY_ID:
            return {
                vacanciesList: state.vacanciesList.filter(vacancy => vacancy.id !== action.payload)
            };
        default:
            return state;
    }
}