import {PROJECTS_CONSTS} from "../constants";

export  const initialState = {
    projectsList: [],
    currentProject: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PROJECTS_CONSTS.GET_PROJECTS_LIST:
            return {
                ...state,
                projectsList: action.payload
            };
        case PROJECTS_CONSTS.GET_PROJECT_BY_ID:
            return {
                ...state,
                currentProject: action.payload
            };
        case PROJECTS_CONSTS.CREATE_PROJECTS:
            return {
                ...state,
                projectsList: [action.payload].concat(state.projectsList)
            };
        case PROJECTS_CONSTS.UPDATE_PROJECTS_BY_ID:
            return {
                ...state,
                projectsList: state.projectsList.map(project =>
                    project.id === action.payload.id ? action.payload : project
                )
            };
        case PROJECTS_CONSTS.DELETE_PROJECTS_BY_ID:
            return {
                ...state,
                projectsList: state.projectsList.filter(project => project.id !== action.payload)
            };
        default:
            return state;
    }
}