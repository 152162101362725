// Import packages
import React, {Component, useEffect} from "react";
import {connect} from "react-redux";
import {Tabs} from 'antd';
import {Link, useHistory, useRouteMatch} from "react-router-dom";

//import assets
import "./vacancies.scss";
import {ReactComponent as ArrowRightIcon} from '../../assets/images/front/icons/ic_arrow_right.svg';

// Import components
import Info from "./Info";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetVacanciesWeb, PathChange} from "../../redux/actions/front";
import {VACANCIES_CATEGORY_TYPES} from "../../constants/constTypes";
import {history} from "../../configs/history";

const {TabPane} = Tabs;

function Vacancies(props) {
    useEffect(() => {
        props.GetVacanciesWeb()

        props.pathChanged && window.scrollTo({
            top: 0,
            behavior: "auto"
        });
    }, [])

    const {vacancies} = props;

    const history = useHistory()

    useEffect(() => {
        // window.onpopstate = ()=> {
        //     console.log('onpopstate');
        //     console.log(history.location, 'loc');
        // }
        // return () => {
        // && history.location.pathname === "any specific path")
        // console.log(history, 'history.action');
        // if (history.action === "POP") {
        //     history.replace(history.location.pathname, /* the new state */);
        //     console.log('aaaaaa');
        // }
        // console.log('bbbbbbbb');
        // };
    }, [])

    useEffect(() => {
        console.log(history.action, 'mount');
        if (history.action === "POP") {
            props.PathChange(true)
        }else {props.PathChange(false)}

        // return () => {
        //     console.log(history.action, 'UN mount');
        //     // && history.location.pathname === "any specific path")
        //     if (history.action === "POP") {
        //         // history.replace(history.location.pathname, /* the new state */);
        //     }
        // };
    }, [history])

    // console.log(vacancies, 'vacancies');
    return <section className="vacancies">
        <Info/>
        <div className={"available-vacancies-wrapper"}>
            {!!vacancies?.length && <div className={"available-vacancies-header"}>
                <div className={"left-part"}>Available Vacancies</div>
                <div className={"right-part"}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="All" key="1">
                            {
                                vacancies.map(item => {
                                    return <Link to={{
                                        pathname: `/vacancy/${item?.slug}`,
                                        state: item
                                    }} className={"tab-content"} key={item?.id}>
                                        <div className={"tab-content-title"}>{item?.title}</div>
                                        <ArrowRightIcon title={""}/>
                                    </Link>
                                })
                            }
                        </TabPane>
                        {
                            Object.keys(VACANCIES_CATEGORY_TYPES)?.map((key) => {
                                return <TabPane tab={VACANCIES_CATEGORY_TYPES[key]?.name} key={key}>
                                    {
                                        vacancies?.filter(item => item.category === key)?.map(item => {
                                            return <Link to={{
                                                pathname: `/vacancy/${item?.slug}`,
                                                state: item
                                            }} className={"tab-content"} key={item?.id}>
                                                <div className={"tab-content-title"}>{item?.title}</div>
                                                <ArrowRightIcon title={""}/>
                                            </Link>
                                        })
                                    }
                                </TabPane>
                            })
                        }
                    </Tabs>
                </div>
            </div>}
        </div>
    </section>
}

const mapDispatchToProps = {
    GetVacanciesWeb,
    PathChange
};

export default connect(mapStateToProps, mapDispatchToProps)(Vacancies);