import {WEB_CONSTS} from "../../constants/front";
import {
    requestWeb,
    _urlContactWeb,
    _urlSliderWeb,
    _urlLanguageWeb,
    _urlCategoriesWeb,
    _urlFeedbackTopicsWeb,
    _urlServicesWeb,
    _urlMenuWeb, _urlStaticTexts, _urlProjectsWeb, _urlVacanciesWeb,
} from "../../api";

/**
 * GRT LANGUAGES LIST
 */

export const GetLanguagesWeb = () => {
    const requestData = {
        url: _urlLanguageWeb,
        method: "GET",
    };
    return dispatch => {
        return requestWeb(requestData)
            .then(res => {
                dispatch({
                    type: WEB_CONSTS.SET_ALL_LANGUAGE_WEB,
                    payload: res.data
                })
            })
            .catch(() => {
            })
    }
};

export const GetTranslations = () => {
    const requestData = {
        url: _urlStaticTexts,
        method: "GET",
    };
    return dispatch => {
        return requestWeb(requestData)
            .then(res => {
                dispatch({
                    type: WEB_CONSTS.SET_TRANSLATIONS,
                    payload: res.data
                });
                return res.data
            })
    }
};

export const ChangeLanguage = (data) => {
    return dispatch => {
        dispatch({
            type: WEB_CONSTS.CHANGE_LANGUAGE,
            payload: data
        })
    }
};

/**
 * GRT CATEGORY LIST
 */

export const GetCategoryWeb = (data) => {
    const requestData = {
        url: _urlCategoriesWeb,
        method: "GET",
        languageFlag: true,
        data
    };
    return dispatch => {
        return requestWeb(requestData)
            .then(res => {
                dispatch({
                    type: WEB_CONSTS.SET_ALL_CATEGORIES_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {
            })
    }
};

/**
 * GRT SLIDER LIST
 */

export const GetSliderWeb = (data) => {
    const requestData = {
        url: _urlSliderWeb,
        method: "GET",
        languageFlag: true,
        data
    };
    return dispatch => {
        return requestWeb(requestData)
            .then(res => {
                dispatch({
                    type: WEB_CONSTS.SET_SLIDER,
                    payload: res.data
                })
            })
            .catch((error) => {

            })
    }
};


/**
 * GRT MENU LIST
 */

export const GetMenuWeb = (data) => {
    const requestData = {
        url: `${_urlMenuWeb}`,
        method: "GET",
        languageFlag: true,
        data
    };
    return dispatch => {
        return requestWeb(requestData)
            .then(res => {
                dispatch({
                    type: WEB_CONSTS.SET_MENU,
                    payload: res.data
                })
            })
            .catch((error) => {
            })
    }
};

/**
 * GRT SERVICES LIST
 */

export const GetServicesWeb = (data) => {
    const requestData = {
        url: _urlServicesWeb,
        method: "GET",
        languageFlag: true,
        data
    };
    return dispatch => {
        return requestWeb(requestData)
            .then(res => {
                dispatch({
                    type: WEB_CONSTS.SET_ALL_SERVICES_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {

            })
    }
};

/**
 * GRT PROJECTS LIST
 */

export const GetProjectsWeb = ({
                                   data,
                                   type= null,
                               } = {}) => {
    const requestData = {
        url: _urlProjectsWeb,
        method: "GET",
        languageFlag: true,
        data
    };

    type && (requestData.url += `?type=${type}`);
    return dispatch => {
        return requestWeb(requestData)
            .then(res => {
                dispatch({
                    type: WEB_CONSTS.SET_ALL_PROJECTS_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {

            })
    }
};

export const GetProjectByIdWeb = (id, data) => {
    const requestData = {
        url: `${_urlProjectsWeb}/${id}`,
        method: "GET",
        languageFlag: true,
        data
    };
    return dispatch => {
        return requestWeb(requestData)
            .then(res => {
                dispatch({
                    type: WEB_CONSTS.SET_PROJECT_BY_ID_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {

            })
    }
};
export const ClearProjectByIdWeb = () => {
    return dispatch => {
        dispatch({
            type: WEB_CONSTS.CLEAR_PROJECT_BY_ID_WEB,
        })
    }
};

/**
 * GRT FEEDBACK TOPICS LIST
 */

export const GetFeedbackTopicsWeb = (data) => {
    const requestData = {
        url: _urlFeedbackTopicsWeb,
        method: "GET",
        languageFlag: true,
        data
    };
    return dispatch => {
        return requestWeb(requestData)
            .then(res => {
                dispatch({
                    type: WEB_CONSTS.SET_FEEDBACK_TOPICS_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {

            })
    }
};

/**
 * ________CONTACTS__________
 */

export const GetContactsWeb = (data) => {
    const requestData = {
        url: _urlContactWeb,
        method: "GET",
        languageFlag: true,
        data
    };

    return dispatch => {
        return requestWeb(requestData)
            .then(res => {
                dispatch({
                    type: WEB_CONSTS.SET_CONTACT_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {
            })
    }
};

export const SendContactsEmail = (data) => {
    const requestData = {
        url: _urlContactWeb,
        method: "POST",
        languageFlag: true,
        data
    };
    return dispatch => {
        return requestWeb(requestData)
    }
};

/**
 * ________VACANCIES__________
 */

export const GetVacanciesWeb = () => {
    const requestData = {
        url: _urlVacanciesWeb,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return requestWeb(requestData)
            .then(res => {
                dispatch({
                    type: WEB_CONSTS.SET_VACANCIES,
                    payload: res.data
                })
            })
            .catch((error) => {
            })
    }
};

export const GetVacanciesWebCount = () => {
    const requestData = {
        url: `${_urlVacanciesWeb}/count`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return requestWeb(requestData)
            .then(res => {
                dispatch({
                    type: WEB_CONSTS.GET_VACANCIES_COUNT,
                    payload: res.data.count
                })
            })
            .catch((error) => {
            })
    }
};

export const ApplyVacancy = (id, data) => {
    const requestData = {
        url: `${_urlVacanciesWeb}/apply/${id}`,
        method: "POST",
        languageFlag: true,
        customHeaders: {
            "Accept": "application/json",
            "Content-Type": "multipart/form-data",
        },
        data
    };
    return dispatch => {
        return requestWeb(requestData)
    }
};

export const GetVacanciesInfo = () => {
    const requestData = {
        url: `${_urlVacanciesWeb}/home`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return requestWeb(requestData)
            .then(res => {
                dispatch({
                    type: WEB_CONSTS.SET_VACANCIES_INFO,
                    payload: res.data
                })
            })
            .catch((error) => {
            })
    }
};


export const GetHeight = (data) => {
    return dispatch => {
        dispatch({
            type: WEB_CONSTS.GET_HEIGHTS,
            payload: data
        })
    }
};

export const PathChange = (data) => {
    return dispatch => {
        dispatch({
            type: WEB_CONSTS.PATH_CHANGE,
            payload: data
        })
    }
};
